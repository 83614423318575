import {
  IMaterial,
  MaterialFileType,
  ThumbnailSize,
} from '@glyph-platforms/glyph-common';

export const getDefaultThumbnail = (
  material: IMaterial,
  size: ThumbnailSize = ThumbnailSize.sm
): string | undefined => {
  if (material.defaultThumbnail) {
    return material.defaultThumbnail.url;
  }

    const thumbnails = material?.thumbnails?.thumbnail?.files;
    const config = thumbnails?.find(
      (thumbnail) => thumbnail.maxSize === size
    );

    if (!config) {
      // fetch the diffuse image thumbnail 
      const diffuseThumbnails = material?.thumbnails?.diffuse?.files;
      if (!diffuseThumbnails) {
        return undefined;
      }
      const diffuseConfig = diffuseThumbnails.find(
        (thumbnail) => thumbnail.maxSize === size
      );
      return diffuseConfig ? diffuseConfig.url : undefined;
    } else {
      return config.url;
    }
};

export const getThumbnail = (
  material: IMaterial,
  fileType: MaterialFileType,
  size: ThumbnailSize
): string | undefined => {
  if (
    !material.thumbnails?.[fileType]?.files
  ) {
    return undefined;
  }

  const thumbnails = material.thumbnails[fileType]!.files;
  const config = thumbnails.find((thumbnail) => thumbnail.maxSize === size);

  if (!config) {
    return undefined;
  }

  return config.url;
};

/** dims in pixels  */
type Dimensions = {
  width: number;
  height: number;
};
// Calculates scale of resized images based on original image scale and size
// Duplicated in the glyph-backend
export const calculateNewScale = (
  originalDimensions: Dimensions,
  resizedDimensions: Dimensions,
  originalScale: {
    width: number;
    height: number;
  }
): {
  width: number;
  height: number;
} => {
  // Calculate the ratio of the new dimension to the original dimension.
  // Here, we use the width for calculation, assuming the image keeps its aspect ratio during resize.
  const resizeRatioW = resizedDimensions.width / originalDimensions.width;
  const resizeRatioH = resizedDimensions.height / originalDimensions.height;

  // The new scale is the original scale multiplied by the resize ratio.
  const newScaleW = originalScale.width * resizeRatioW;
  const newScaleH = originalScale.height * resizeRatioH;

  return {
    width: newScaleW,
    height: newScaleH,
  };
};

import { Glyph } from "@glyph-platforms/glyph-common";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as glyphAPI from "../api/backend";
import { CreateGlyphPayload } from "../api/backend/glyphs";
import { getGlyphs } from "../api/db";
import Alert from "../components/Alert";
import LoadingScreen from "../components/LoadingScreen";
import { useAuth } from "../hooks/useAuth";
import { getDefaultGlyphThumbnail } from "../lib/glyphs";

// const themes = Object.keys(glyphCodebook).map((key: any) => ({
//   value: Number(key),
//   colors: {
//     r: glyphCodebook[key].r,
//     g: glyphCodebook[key].g,
//     b: glyphCodebook[key].b,
//   },
// }));

const GlyphsPage: React.FC = () => {
  const [selectedSort, setSelectedSort] = useState<string>("Latest");
  const [showModal, setShowModal] = useState<boolean>(false);
  const { role } = useAuth();
  const [loading, setLoading] = useState(true);
  const [glyphs, setGlyphs] = useState<Glyph[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "danger" | "success" | "warning" | "info"
  >("danger");
  const navigate = useNavigate();
  const createGlyphFormRef = useRef<HTMLFormElement>(null);
  const handleModalClose = () => setShowModal(false);
  const handleModalOpen = () => setShowModal(true);

  const maxCharacterLimit20 = 20;
  const maxCharacterLimit500 = 500;

  if (!role) {
    navigate("/login");
  }

  const triggerAlert = (
    message: string,
    type: "danger" | "success" | "warning" | "info"
  ) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  // const ColorBox = ({ color }: { color: string }) => (
  //   <div
  //     style={{
  //       backgroundColor: color,
  //       width: '20px',
  //       height: '20px',
  //       display: 'inline-block',
  //       marginRight: '5px',
  //     }}
  //   ></div>
  // );

  // const getDropdownTitle = () => {
  //   const selectedTheme = themes.find(theme => theme.value === createGlyphFormData.codebookColor);
  //   if (selectedTheme) {
  //     return (
  //       <>
  //         <ColorBox color={selectedTheme.colors.r} />
  //         <ColorBox color={selectedTheme.colors.g} />
  //         <ColorBox color={selectedTheme.colors.b} />
  //       </>
  //     );
  //   }
  //   return "Select a theme";
  // };

  const [createGlyphFormData, setCreateGlyphFormData] =
    useState<CreateGlyphPayload>({
      name: "",
      description: "",
      size: {
        width: 500,
        height: 500,
      },
      scale: 1,
      codebookColor: 1,
    });

  useEffect(() => {
    if (role && role.organizationID) {
      syncGlyphs(role.organizationID);
    }
  }, [role]);

  const filteredGlyphs = useMemo(() => {
    let sortedGlyphs = [...glyphs];
    if (selectedSort === "Latest") {
      sortedGlyphs.sort((a, b) => b.timeCreatedUnix - a.timeCreatedUnix);
    } else if (selectedSort === "Oldest") {
      sortedGlyphs.sort((a, b) => a.timeCreatedUnix - b.timeCreatedUnix);
    }

    if (!searchTerm) {
      return sortedGlyphs;
    }

    return sortedGlyphs.filter((glyph) => {
      return (
        glyph.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (glyph.description &&
          glyph.description.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    });
  }, [glyphs, searchTerm, selectedSort]);

  const syncGlyphs = async (orgId: string) => {
    console.log("fetching glyphs");
    try {
      setLoading(true);
      const glyphs = await getGlyphs(orgId);
      setGlyphs(glyphs);
    } catch (err: any) {
      triggerAlert(err.message, "danger");
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const getBadgeProperties = (
    status: "queued" | "creating" | "active" | "error"
  ) => {
    switch (status) {
      case "queued":
        return { color: "info", text: "Queued" };
      case "creating":
        return { color: "warning", text: "Creating" };
      case "active":
        return { color: "active", text: "Active" };
      case "error":
      default:
        return { color: "inactive", text: "Error" };
    }
  };

  const GlyphCard = (glyph: Glyph) => {
    const truncatedDescription = glyph.description
      ? glyph.description.slice(0, 100)
      : "No description";
    const truncatedName = glyph.name.slice(0, 20);
    const { color, text } = getBadgeProperties(glyph.status);
    return (
      <Col md={6} lg={4} xl={3} className="mb-4">
        <Card
          className="glyph-card"
          onClick={() => handleCardClick(glyph.id)}
          style={{ cursor: "pointer" }}
        >
          <Card.Header className="glyph-card-header">
            <h5>{truncatedName}</h5>
          </Card.Header>
          <Card.Body className="glyph-card-body">
            <Row>
              <Col xs={4}>
                <img
                  src={
                    getDefaultGlyphThumbnail(glyph)
                      ? getDefaultGlyphThumbnail(glyph)
                      : "placeholder-small.png"
                  }
                  alt="Glyph"
                  style={{ width: "80px", height: "80px", objectFit: "cover" }}
                />
              </Col>
              <Col xs={8}>
                <Card.Text> {truncatedDescription}</Card.Text>
              </Col>
            </Row>
            <Badge bg={color} className="mt-2">
              {text}
            </Badge>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const handleCardClick = (glyphId: string) => {
    navigate(`/glyph-view/${glyphId}`);
  };

  const handleCreateGlyphSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (createGlyphFormRef.current) {
      if (!createGlyphFormRef.current.checkValidity()) {
        createGlyphFormRef.current.reportValidity();
        return;
      }
    }
    const payload: CreateGlyphPayload = {
      name: createGlyphFormData.name,
      description: createGlyphFormData.description,
      size: {
        width: Number(createGlyphFormData.size.width),
        height: Number(createGlyphFormData.size.height),
      },
      scale: Number(createGlyphFormData.scale),
      codebookColor: Number(createGlyphFormData.codebookColor),
    };

    await createGlyphSubmit(payload);
    handleModalClose();
  };

  const createGlyphSubmit = async (data: CreateGlyphPayload) => {
    if (!role) {
      return;
    }
    try {
      setLoading(true);
      await glyphAPI.createGlyph({
        name: data.name,
        description: data.description,
        size: {
          width: data.size.width,
          height: data.size.height,
        },
        scale: data.scale,
        codebookColor: data.codebookColor,
      });
      triggerAlert("Glyph is in queue", "success");
      syncGlyphs(role.organizationID);
    } catch (err: any) {
      if (err.response && err.response.data && err.response.data.error) {
        triggerAlert(err.response.data.error, "danger");
      } else {
        triggerAlert(err.message, "danger");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Alert
        show={showAlert}
        message={alertMessage}
        type={alertType}
        onClose={closeAlert}
      />
      <Container>
        {loading ? (
          <div className="container-for-loading">
            <LoadingScreen displayText="Loading glyphs..." />
          </div>
        ) : (
          <>
            <Row className="mt-4">
              <Col>
                <h1 className="page-heading">Glyphs Archive</h1>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={10}>
                <Form.Control
                  className="search-bar search-materials-textbox form-control"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => onSearch(e.target.value)}
                />
              </Col>
              <Col md={2} className="mt-3 mt-md-0">
                <Button className="btn-green" onClick={handleModalOpen}>
                  Create Glyph
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col
                md={7}
                className="d-flex align-items-center  page-sort-filter"
              >
                <Form.Label className="mr-2">
                  <span className="page-text">Sort by:</span>{" "}
                </Form.Label>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={selectedSort}
                  onSelect={(e) => {
                    if (e) setSelectedSort(e);
                  }}
                  className="filter-dropdown-button"
                >
                  <Dropdown.Item eventKey="Latest">Latest</Dropdown.Item>
                  <Dropdown.Item eventKey="Oldest">Oldest</Dropdown.Item>
                </DropdownButton>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p style={{ color: "white" }}>
                  {searchTerm
                    ? `Showing ${filteredGlyphs.length} / ${glyphs.length} results`
                    : `You have ${glyphs.length} Glyphs`}
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              {filteredGlyphs.map((glyph, index) => GlyphCard(glyph))}
            </Row>

            <Modal show={showModal} onHide={handleModalClose}>
              <Modal.Header>
                <Modal.Title>Create Glyph</Modal.Title>
                <button
                  type="button"
                  className="close modal-close-btn"
                  aria-label="Close"
                  onClick={handleModalClose}
                  style={{ color: "white" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <Form ref={createGlyphFormRef}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                      type="text"
                      value={createGlyphFormData.name}
                      onChange={(e) =>
                        setCreateGlyphFormData((prevState) => ({
                          ...prevState,
                          name: e.target.value,
                        }))
                      }
                      placeholder="Enter a name"
                      maxLength={maxCharacterLimit20}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={createGlyphFormData.description}
                      onChange={(e) =>
                        setCreateGlyphFormData((prevState) => ({
                          ...prevState,
                          description: e.target.value,
                        }))
                      }
                      placeholder="Enter a description"
                      maxLength={maxCharacterLimit500}
                    />
                  </Form.Group>

                  <Row className="mb-3">
                    <Col>
                      <Form.Label>Width in Millimeters*</Form.Label>
                      <Form.Control
                        type="number"
                        onChange={(e) =>
                          setCreateGlyphFormData((prevState) => ({
                            ...prevState,
                            size: {
                              ...prevState.size,
                              width: Number(e.target.value),
                            },
                          }))
                        }
                        placeholder="500"
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Label>Height in Millimeters*</Form.Label>
                      <Form.Control
                        type="number"
                        onChange={(e) =>
                          setCreateGlyphFormData((prevState) => ({
                            ...prevState,
                            size: {
                              ...prevState.size,
                              height: Number(e.target.value),
                            },
                          }))
                        }
                        placeholder="500"
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs={6}>
                      <Form.Label>Scale:</Form.Label>
                      <Form.Control
                        type="number"
                        value={createGlyphFormData.scale}
                        onChange={(e) =>
                          setCreateGlyphFormData((prevState) => ({
                            ...prevState,
                            scale: Number(e.target.value),
                          }))
                        }
                        placeholder="Enter the scale (unitless)"
                      />
                    </Col>
                    {/* <Col>
                      <Form.Label>Theme:</Form.Label>
                      <DropdownButton
                        id="theme-dropdown"
                        title={getDropdownTitle()}
                        onSelect={(value: any) => setCreateGlyphFormData(prevState => ({ ...prevState, codebookColor: Number(value) }))}
                      >
                        {themes.map((theme, index) => (
                          <Dropdown.Item className="theme-dropdown-item" key={index} eventKey={theme.value.toString()}>
                            <ColorBox color={theme.colors.r} />
                            <ColorBox color={theme.colors.g} />
                            <ColorBox color={theme.colors.b} />
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </Col> */}
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-transparent-invert"
                  onClick={handleCreateGlyphSubmit}
                >
                  Create Glyph
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Container>
    </>
  );
};

export default GlyphsPage;

import {
  Glyph,
  GlyphID,
  IMaterial,
  MaterialID,
  MaterialVisibility,
  OrganizationID,
} from "@glyph-platforms/glyph-common";
import React, { useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  FormCheck,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  CreateMaterialArgs,
  assignMaterialsToGlyphs,
  createMaterial,
  getGlyphs,
  getMaterials,
} from "../api/db";
import Alert from "../components/Alert";
import LoadingScreen from "../components/LoadingScreen";
import { useAuth } from "../hooks/useAuth";
import { getDefaultGlyphThumbnail } from "../lib/glyphs";
import { search } from "../lib/images";
import { getDefaultThumbnail } from "../lib/materials";

const PUBLIC_TAG: MaterialVisibility = "public";
const PRIVATE_TAG: MaterialVisibility = "private";

// Value for all dropdown

const colors_options = [
  { value: "Black", label: "Black" },
  { value: "Blue", label: "Blue" },
  { value: "Brown", label: "Brown" },
  { value: "Cyan", label: "Cyan" },
  { value: "Gold", label: "Gold" },
  { value: "Gray", label: "Gray" },
  { value: "Green", label: "Green" },
  { value: "Magenta", label: "Magenta" },
  { value: "Orange", label: "Orange" },
  { value: "Pink", label: "Pink" },
  { value: "Purple", label: "Purple" },
  { value: "Red", label: "Red" },
  { value: "Sliver", label: "Sliver" },
  { value: "White", label: "White" },
  { value: "Yellow", label: "Yellow" },
];

const utility_options = [
  { value: "Accessories", label: "Accessories" },
  { value: "Art", label: "Art" },
  { value: "Apparel", label: "Apparel" },
  { value: "Bedding", label: "Bedding" },
  { value: "Upholstery", label: "Upholstery" },
  { value: "Wall-covering", label: "Wall-covering" },
  { value: "Doors", label: "Doors" },
];

const design_characteristics_options = [
  { value: "Prismatic Plates", label: "Prismatic Plates" },
  { value: "Nightsteel Strands", label: "Nightsteel Strands" },
  { value: "Light Vibrant Streams", label: "Light Vibrant Streams" },
  { value: "Omniscient Interface", label: "Omniscient Interface" },
  { value: "Chromatic Resting", label: "Chromatic Resting" },
  { value: "Light Cosmic Kaleidoscope", label: "Light Cosmic Kaleidoscope" },
  { value: "Dark Cosmic Kaleidoscope", label: "Dark Cosmic Kaleidoscope" },
  { value: "Mystical Mosaic", label: "Mystical Mosaic" },
  { value: "Celestial Halo", label: "Celestial Halo" },
  { value: "Whispering Finish", label: "Whispering Finish" },
  { value: "Frosty Glaze", label: "Frosty Glaze" },
  { value: "Misty Veil", label: "Misty Veil" },
  { value: "Mossy Mirage", label: "Mossy Mirage" },
  { value: "Swirling Abyss", label: "Swirling Abyss" },
  { value: "Cobalt Calm", label: "Cobalt Calm" },
  { value: "Petrified Forms", label: "Petrified Forms" },
  { value: "Enlivening Jade", label: "Enlivening Jade" },
  { value: "Serene Smoothness", label: "Serene Smoothness" },
  { value: "Foggy Enigma", label: "Foggy Enigma" },
  { value: "Rustic Patina", label: "Rustic Patina" },
  { value: "Lunar soil", label: "Lunar soil" },
  { value: "Noir Glitches", label: "Noir Glitches" },
  { value: "Mirage Horizons", label: "Mirage Horizons" },
  { value: "Majestic Fog", label: "Majestic Fog" },
  { value: "Enigmatic Chalk", label: "Enigmatic Chalk" },
];

export const design_type_options = [
  { value: "Space Colony", label: "Space Colony" },
  {
    value: "Archaeology of Future's Past",
    label: "Archaeology of Future's Past",
  },
  { value: "Multiworld Rave ", label: "Multiworld Rave " },
  { value: "Infinite Sanctuary", label: "Infinite Sanctuary" },
  { value: "Augmented Nature", label: "Augmented Nature" },
  { value: "Floral", label: "Floral" },
  { value: "Abstract", label: "Abstract" },
  { value: "Animal", label: "Animal" },
  { value: "Botanical", label: "Botanical" },
  { value: "Checkered", label: "Checkered" },
  { value: "Geometric", label: "Geometric" },
  { value: "Chipboard Wood", label: "Chipboard Wood" },
  { value: "Fiberboard Wood", label: "Fiberboard Wood" },
  { value: "OSB Wood", label: "OSB Wood" },
  { value: "Oak Wood", label: "Oak Wood" },
  { value: "Balsa Wood", label: "Balsa Wood" },
  { value: "Maple Wood", label: "Maple Wood" },
  { value: "Pine Wood", label: "Pine Wood" },
  { value: "Teak Wood", label: "Teak Wood" },
  { value: "Walnut Wood", label: "Walnut Wood" },
  { value: "Cherry Wood", label: "Cherry Wood" },
  { value: "Birch Wood", label: "Birch Wood" },
  { value: "Redwood", label: "Redwood" },
  { value: "Ash Wood", label: "Ash Wood" },
  { value: "Cedar Wood", label: "Cedar Wood" },
  { value: "Mahogany Wood", label: "Mahogany Wood" },
  { value: "Rosewood", label: "Rosewood" },
  { value: "SandStone", label: "SandStone" },
  { value: "Tuff Stone", label: "Tuff Stone" },
  { value: "White Sand", label: "White Sand" },
  {
    value: "Bianco Carrara Marble Stone",
    label: "Bianco Carrara Marble Stone",
  },
  { value: "Travertine Marble Stone", label: "Travertine Marble Stone" },
  { value: "Pentelikon Marble Stone", label: "Pentelikon Marble Stone" },
  { value: "Granite Stone", label: "Granite Stone" },
  { value: "Limestone", label: "Limestone" },
  { value: "Slate Stone", label: "Slate Stone" },
  { value: "Basalt Stone", label: "Basalt Stone" },
  { value: "Onyx Stone", label: "Onyx Stone" },
  { value: "Obsidian Stone", label: "Obsidian Stone" },
  { value: "Rusted Iron Metal", label: "Rusted Iron Metal" },
  { value: "Copper Metal", label: "Copper Metal" },
  { value: "Tarnished Silver Metal", label: "Tarnished Silver Metal" },
  { value: "Gold Foil Metal", label: "Gold Foil Metal" },
  { value: "Zinc Metal", label: "Zinc Metal" },
  { value: "Cotton Wall Fabric", label: "Cotton Wall Fabric" },
  { value: "Silk Wall Fabric", label: "Silk Wall Fabric" },
  { value: "Denim Wall Fabric", label: "Denim Wall Fabric" },
  { value: "Velvet Wall Fabric", label: "Velvet Wall Fabric" },
  { value: "Wool Wall Fabric", label: "Wool Wall Fabric" },
  { value: "White Porcelain", label: "White Porcelain" },
  { value: "Celadon Porcelain", label: "Celadon Porcelain" },
  { value: "Rose Porcelain", label: "Rose Porcelain" },
  { value: "Jade Green Porcelain", label: "Jade Green Porcelain" },
];

const MaterialsPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [assignGlyphsloading, setAssignGlyphsloading] = useState(false);
  const { user, role } = useAuth();
  const [materials, setMaterials] = useState<IMaterial[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSort, setSelectedSort] = useState<string>("Latest");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [selectedMaterials, setSelectedMaterials] = useState<MaterialID[]>([]);
  const [selectedGlyphs, setSelectedGlyphs] = useState<GlyphID[]>([]);
  const [glyphs, setGlyphs] = useState<Glyph[]>([]);
  const [glyphSearchTerm, setGlyphSearchTerm] = useState("");
  const [availableUtilities, setAvailableUtilities] = useState<string[]>([]);
  const [availableCollections, setAvailableCollections] = useState<string[]>(
    []
  );
  const [availableCharacteristics, setAvailableCharacteristics] = useState<
    string[]
  >([]);
  const [availableColors, setAvailableColors] = useState<string[]>([]);
  const [availableDesignTypes, setAvailableDesignTypes] = useState<string[]>(
    []
  );
  const [selectedUtilities, setSelectedUtilities] = useState<Set<string>>(
    new Set()
  );
  const [selectedCollections, setSelectedCollections] = useState<Set<string>>(
    new Set()
  );
  const [selectedCharacteristics, setSelectedCharacteristics] = useState<
    Set<string>
  >(new Set());
  const [selectedColors, setSelectedColors] = useState<Set<string>>(new Set());
  const [selectedDesignTypes, setSelectedDesignTypes] = useState<Set<string>>(
    new Set()
  );
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "danger" | "success" | "warning" | "info"
  >("danger");
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleCreateClose = () => setShowCreateModal(false);
  const handleCreateShow = () => setShowCreateModal(true);
  const hasSelected = selectedMaterials.length > 0;
  const navigate = useNavigate();

  const triggerAlert = (
    message: string,
    type: "danger" | "success" | "warning" | "info"
  ) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    let utilities: Set<string> = new Set();
    let collections: Set<string> = new Set();
    let characteristics: Set<string> = new Set();
    let colors: Set<string> = new Set();
    let designTypes: Set<string> = new Set();

    materials.forEach((material) => {
      material.utility.forEach((u) => utilities.add(u));
      material.collection.forEach((c) => collections.add(c));
      material.characteristics.forEach((ch) => characteristics.add(ch));
      material.colors.forEach((co) => colors.add(co));
      material.designType.forEach((dt) => designTypes.add(dt));
    });

    setAvailableUtilities([...utilities]);
    setAvailableCollections([...collections]);
    setAvailableCharacteristics([...characteristics]);
    setAvailableColors([...colors]);
    setAvailableDesignTypes([...designTypes]);
  }, [materials]);

  const toggleFilterSelection = (
    value: string,
    setSelected: React.Dispatch<React.SetStateAction<Set<string>>>
  ) => {
    setSelected((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(value)) {
        newSet.delete(value);
      } else {
        newSet.add(value);
      }
      return newSet;
    });
  };

  useEffect(() => {
    const syncMaterials = async (orgId: OrganizationID) => {
      try {
        setLoading(true);
        const materials = await getMaterials(orgId);
        // // Only save a few materials in local storage
        // // These are used to display immediately while the rest are fetched
        // const materialsInLocalStorage = materials.slice(
        //   0,
        //   15
        // );
        // setMaterialCache(materialsInLocalStorage);
        setMaterials(materials);
      } catch (err: any) {
        triggerAlert(err.message, "danger");
      } finally {
        setLoading(false);
      }
    };
    if (role) {
      syncMaterials(role.organizationID);
    } else if (role === null) {
      setMaterials([]);
      // setMaterialCache([]);
    }
  }, [setMaterials, role]);

  const handleCardClick = (materialId: string) => {
    navigate(`/material-view/${materialId}`);
  };

  const filteredMaterials = useMemo(() => {
    let results = materials;

    if (searchTerm) {
      results = results.filter((material) => {
        return (
          material.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (material.description &&
            material.description
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))
        );
      });
    }

    results = results.filter(
      (material) =>
        (selectedUtilities.size === 0 ||
          [...material.utility].some((u) => selectedUtilities.has(u))) &&
        (selectedCollections.size === 0 ||
          [...material.collection].some((c) => selectedCollections.has(c))) &&
        (selectedCharacteristics.size === 0 ||
          [...material.characteristics].some((ch) =>
            selectedCharacteristics.has(ch)
          )) &&
        (selectedColors.size === 0 ||
          [...material.colors].some((co) => selectedColors.has(co))) &&
        (selectedDesignTypes.size === 0 ||
          [...material.designType].some((dt) => selectedDesignTypes.has(dt)))
    );

    return results.sort((a, b) => {
      if (!a.timeCreated || !b.timeCreated) {
        return 0;
      }
      if (selectedSort === "Latest") {
        return (
          new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime()
        );
      } else if (selectedSort === "Oldest") {
        return (
          new Date(a.timeCreated).getTime() - new Date(b.timeCreated).getTime()
        );
      }
      return 0;
    });
  }, [
    materials,
    searchTerm,
    selectedSort,
    selectedUtilities,
    selectedCollections,
    selectedCharacteristics,
    selectedColors,
    selectedDesignTypes,
  ]);

  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const maxCharacterLimit20 = 20;
  const maxCharacterLimit500 = 500;

  const [createMaterialFormData, setCreateMaterialFormData] = useState({
    name: "",
    publicField: "public",
    description: "",
    width: "",
    height: "",
    collections: [],
    colors: [],
    characteristics: [],
    utility: [],
    designType: [],
  });

  const handleCreateMaterialFormChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setCreateMaterialFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onCreateMaterialSubmit = async (data: CreateMaterialArgs) => {
    if (!user || !role) {
      return;
    }
    try {
      setLoading(true);
      const response = await createMaterial(role.organizationID, user.id, data);
      triggerAlert(response.message, "warning");
    } catch (err: any) {
      triggerAlert(err.message, "danger");
    } finally {
      setLoading(false);
      handleCreateClose();
    }
  };

  const handleCreateMaterialSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const reshapedData: CreateMaterialArgs = {
      name: createMaterialFormData.name,
      description: createMaterialFormData.description,
      colors: createMaterialFormData.colors,
      scale: {
        width: Number(createMaterialFormData.width),
        height: Number(createMaterialFormData.height),
      },
      price: {
        value: 0,
        currency: "USD",
        precision: 2,
      },
      collection: createMaterialFormData.collections,
      characteristics: createMaterialFormData.characteristics,
      utility: createMaterialFormData.utility,
      designType: createMaterialFormData.designType,
      visibility:
        createMaterialFormData.publicField === PUBLIC_TAG
          ? PUBLIC_TAG
          : PRIVATE_TAG,
    };

    onCreateMaterialSubmit(reshapedData);
  };

  const handleBadgeAddition = (
    e: React.KeyboardEvent<HTMLInputElement>,
    field: keyof typeof createMaterialFormData
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const target = e.target as HTMLInputElement;
      const value = target.value.trim();
      if (value) {
        setCreateMaterialFormData((prev) => ({
          ...prev,
          [field]: [...prev[field], value],
        }));
        target.value = "";
      }
    }
  };

  const handleBadgeAdditionOnChange = (
    e: { value: string; label: string },
    field: keyof typeof createMaterialFormData
  ) => {
    const value = e.value.trim();
    if (value) {
      setCreateMaterialFormData((prev) => ({
        ...prev,
        [field]: [...prev[field], value],
      }));
    }
  };

  const removeBadge = (
    field: keyof typeof createMaterialFormData,
    index: number
  ) => {
    setCreateMaterialFormData((prev) => {
      const newBadges = [...prev[field]];
      newBadges.splice(index, 1);
      return {
        ...prev,
        [field]: newBadges,
      };
    });
  };

  const checkGlyph = (glyphId: GlyphID) => {
    setSelectedGlyphs((allChecks) => {
      if (allChecks.includes(glyphId)) {
        return allChecks.filter((id) => id !== glyphId);
      } else {
        return [...allChecks, glyphId];
      }
    });
  };

  const handleMaterialCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    materialId: string
  ) => {
    if (e.target.checked) {
      setSelectedMaterials((prevSelectedMaterials) => [
        ...prevSelectedMaterials,
        materialId,
      ]);
    } else {
      setSelectedMaterials((prevSelectedMaterials) =>
        prevSelectedMaterials.filter((id) => id !== materialId)
      );
    }
  };

  const syncGlyphs = async (orgId: string) => {
    try {
      setLoading(true);
      const glyphs = await getGlyphs(orgId);
      setGlyphs(glyphs);
    } catch (err: any) {
      triggerAlert(err.message, "danger");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (role && role.organizationID) {
      syncGlyphs(role.organizationID);
    }
  }, [role]);

  const filteredGlyphs = useMemo(() => {
    if (!glyphSearchTerm) {
      return glyphs;
    }
    return glyphs.filter((glyph) => {
      return (
        glyph.name.toLowerCase().includes(glyphSearchTerm.toLowerCase()) ||
        (glyph.description &&
          glyph.description
            .toLowerCase()
            .includes(glyphSearchTerm.toLowerCase()))
      );
    });
  }, [glyphs, glyphSearchTerm]);

  const onGlyphSearch = (value: string) => {
    setGlyphSearchTerm(value);
  };

  const handleOk = async () => {
    if (!user) {
      triggerAlert("Please log in!", "danger");
      return;
    }
    if (!role) {
      triggerAlert("Please select an organization!", "danger");
      return;
    }
    if (selectedGlyphs.length === 0) {
      triggerAlert("Please select a Glyph", "danger");
      return;
    }
    if (selectedGlyphs) {
      setAssignGlyphsloading(true);
      try {
        await assignMaterialsToGlyphs(
          user.id,
          role.organizationID,
          selectedGlyphs,
          selectedMaterials
        );
        handleClose();
        triggerAlert(
          `Material${
            selectedMaterials.length > 1 ? "s" : ""
          } assigned to Glyph${selectedGlyphs.length > 1 ? "s" : ""}!`,
          "success"
        );
      } catch (err: any) {
        triggerAlert(err.message, "danger");
      } finally {
        setAssignGlyphsloading(false);
      }
    }
  };

  return (
    <>
      <Alert
        show={showAlert}
        message={alertMessage}
        type={alertType}
        onClose={closeAlert}
      />
      <Container>
        {loading ? (
          <div className="container-for-loading">
            <LoadingScreen displayText="Loading materials..." />
          </div>
        ) : assignGlyphsloading ? (
          <div className="container-for-loading">
            <LoadingScreen displayText="Assigning Glyphs..." />
          </div>
        ) : (
          <>
            <Row className="mt-4">
              <Col>
                <h1 className="page-heading">Materials Archive</h1>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={2}>
                <Button
                  onClick={handleShow}
                  className="btn-pink"
                  variant="outline-light"
                  style={{ width: "100%" }}
                >
                  Assign Glyph
                </Button>
              </Col>
              <Col md={8} className="mt-3 mt-md-0">
                <Form.Control
                  type="text"
                  placeholder="Search"
                  className="search-materials-textbox"
                  onChange={(e) => onSearch(e.target.value)}
                />
              </Col>
              <Col md={2} className="mt-3 mt-md-0">
                <Button
                  onClick={handleCreateShow}
                  className="btn-green"
                  style={{ width: "100%" }}
                >
                  Create Material
                </Button>
              </Col>
            </Row>
            <Row className="mt-3 material-filters">
              <Col md={2}>
                <DropdownButton id="dropdown-utility" title="Utility">
                  <Dropdown.Item
                    onClick={() => setSelectedUtilities(new Set())}
                  >
                    None
                  </Dropdown.Item>
                  {availableUtilities.map((u, idx) => (
                    <Dropdown.Item
                      key={idx}
                      onClick={() =>
                        toggleFilterSelection(u, setSelectedUtilities)
                      }
                    >
                      {u}
                      {selectedUtilities.has(u) ? "✔️ " : ""}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>

              <Col md={2}>
                <DropdownButton id="dropdown-collection" title="Collection">
                  <Dropdown.Item
                    onClick={() => setSelectedCollections(new Set())}
                  >
                    None
                  </Dropdown.Item>
                  {availableCollections.map((c, idx) => (
                    <Dropdown.Item
                      key={idx}
                      onClick={() =>
                        toggleFilterSelection(c, setSelectedCollections)
                      }
                    >
                      {c}
                      {selectedCollections.has(c) ? "✔️ " : ""}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>

              <Col md={2}>
                <DropdownButton
                  id="dropdown-characteristics"
                  title="Characteristics"
                >
                  <Dropdown.Item
                    onClick={() => setSelectedCharacteristics(new Set())}
                  >
                    None
                  </Dropdown.Item>
                  {availableCharacteristics.map((ch, idx) => (
                    <Dropdown.Item
                      key={idx}
                      onClick={() =>
                        toggleFilterSelection(ch, setSelectedCharacteristics)
                      }
                    >
                      {ch}
                      {selectedCharacteristics.has(ch) ? "✔️ " : ""}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>

              <Col md={2}>
                <DropdownButton id="dropdown-color" title="Color">
                  <Dropdown.Item onClick={() => setSelectedColors(new Set())}>
                    None
                  </Dropdown.Item>
                  {availableColors.map((co, idx) => (
                    <Dropdown.Item
                      key={idx}
                      onClick={() =>
                        toggleFilterSelection(co, setSelectedColors)
                      }
                    >
                      {co}
                      {selectedColors.has(co) ? "✔️ " : ""}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>

              <Col md={2}>
                <DropdownButton id="dropdown-design" title="Design">
                  <Dropdown.Item
                    onClick={() => setSelectedDesignTypes(new Set())}
                  >
                    None
                  </Dropdown.Item>
                  {availableDesignTypes.map((dt, idx) => (
                    <Dropdown.Item
                      key={idx}
                      onClick={() =>
                        toggleFilterSelection(dt, setSelectedDesignTypes)
                      }
                    >
                      {dt}
                      {selectedDesignTypes.has(dt) ? "✔️ " : ""}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
              <Col md={2}>&nbsp;</Col>
            </Row>

            <Row className="mt-3">
              <Col
                md={7}
                className="d-flex align-items-center page-sort-filter"
              >
                <Form.Label className="mr-2 page-text">Sort by:</Form.Label>
                <DropdownButton
                  id="dropdown-sort-button"
                  title={selectedSort}
                  onSelect={(e) => setSelectedSort(e ? e : selectedSort)}
                >
                  <Dropdown.Item eventKey="Latest">Latest</Dropdown.Item>
                  <Dropdown.Item eventKey="Oldest">Oldest</Dropdown.Item>
                </DropdownButton>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p style={{ color: "white" }}>
                  {searchTerm
                    ? `Showing ${filteredMaterials.length} / ${materials.length} results`
                    : `You have ${materials.length}  Materials`}
                </p>
              </Col>
            </Row>

            <Row className="material-card-wrapper mt-3">
              {filteredMaterials.map((material, index) => (
                <div key={index} className="mb-4">
                  <Card className="material-card" style={{ cursor: "pointer" }}>
                    <FormCheck
                      inline
                      className="material-checkbox"
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => handleMaterialCheck(e, material.id)}
                    />
                    <Image
                      src={
                        getDefaultThumbnail(material)
                          ? getDefaultThumbnail(material)
                          : "placeholder-small.png"
                      }
                      alt="Material Image"
                      onClick={() => handleCardClick(material.id)}
                      onError={(e) =>
                        (e.currentTarget.src = "placeholder-small.png")
                      }
                      rounded
                      fluid
                    />

                    <Card.Body>
                      <Card.Title className="d-flex justify-content-between">
                        <span>{material.name.slice(0, 20)}</span>
                      </Card.Title>
                      <Card.Text>
                        {material.collection &&
                          material.collection
                            .slice(0, 3)
                            .map((collection, index) => (
                              <>
                                {collection}
                                &nbsp;
                              </>
                            ))}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </Row>

            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header>
                <Modal.Title>Assign Materials to Glyph</Modal.Title>
                <button
                  type="button"
                  className="close modal-close-btn"
                  aria-label="Close"
                  onClick={handleClose}
                  style={{ color: "white" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <p style={{ color: "white" }}>
                  {hasSelected
                    ? `Select a Glyph to assign the selected ${selectedMaterials.length} materials`
                    : `You have ${materials.length} materials`}
                </p>
                <Form>
                  <div className="input-group">
                    <Form.Control
                      type="search"
                      placeholder="Search Glyph"
                      className="d-inline-block assign-materials-search-bar"
                      onChange={(e) => onGlyphSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <Button
                        variant="secondary"
                        className="d-inline-block ml-2 assign-materials-search-button"
                        type="submit"
                      >
                        <img src={search.default} alt="Search" width="20" />
                      </Button>
                    </div>
                  </div>
                </Form>
                <div className="assign-glyphs-container">
                  {filteredGlyphs.map((glyph) => (
                    <div key={glyph.id} className="d-flex mt-4">
                      <Form.Check
                        inline
                        type="checkbox"
                        className="assign-material-checkbox"
                        onChange={() => checkGlyph(glyph.id)}
                        checked={selectedGlyphs.includes(glyph.id)}
                      />
                      <Image
                        src={getDefaultGlyphThumbnail(glyph)}
                        alt="Glyph Image"
                        rounded
                        fluid
                        className="d-inline-block material-image-thumbnail mr-2"
                        width="50"
                      />
                      <div className="material-text ps-3">
                        <h6>{glyph.name}</h6>
                        <p>{glyph.description || "No description"}</p>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="outline-light"
                  className="btn-assign-glyphs"
                  onClick={handleOk}
                >
                  Assign Glyphs
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showCreateModal} onHide={handleCreateClose}>
              <Modal.Header>
                <Modal.Title>Create Material</Modal.Title>
                <button
                  type="button"
                  className="close modal-close-btn"
                  aria-label="Close"
                  onClick={handleCreateClose}
                  style={{ color: "white" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Form onSubmit={handleCreateMaterialSubmit}>
                <Modal.Body>
                  <Form.Group as={Row}>
                    <Col md={6}>
                      <Form.Label>Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter a name"
                        name="name"
                        maxLength={maxCharacterLimit20}
                        onChange={handleCreateMaterialFormChange}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Label>Visibility</Form.Label>
                      <Form.Select
                        placeholder="Public"
                        name="publicField"
                        required
                        onChange={handleCreateMaterialFormChange}
                      >
                        <option value={PUBLIC_TAG}>Public</option>
                        <option value={PRIVATE_TAG}>Private</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="align-items-end">
                    <Col md={6}>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter a description"
                        name="description"
                        maxLength={maxCharacterLimit500}
                        onChange={handleCreateMaterialFormChange}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label>Real World Scale (Width)*</Form.Label>
                      <Form.Control
                        type="number"
                        defaultValue="100"
                        name="width"
                        onChange={handleCreateMaterialFormChange}
                        required
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Label>Real World Scale (Height)*</Form.Label>
                      <Form.Control
                        type="number"
                        defaultValue="100"
                        name="height"
                        onChange={handleCreateMaterialFormChange}
                        required
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Col md={6}>
                      <Form.Label>Collections</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Specify collections for your material"
                        maxLength={maxCharacterLimit20}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                          handleBadgeAddition(e, "collections")
                        }
                      />
                      {createMaterialFormData.collections.map(
                        (collection, index) => (
                          <Badge
                            as="span"
                            pill
                            className="badge text-dark bg-light mr-2 mb-2"
                            key={index}
                          >
                            {collection}
                            <span
                              className="badge-close"
                              onClick={() => removeBadge("collections", index)}
                            >
                              &times;
                            </span>
                          </Badge>
                        )
                      )}
                    </Col>

                    <Col md={6}>
                      <Form.Label>Color</Form.Label>
                      <Select
                        placeholder="Choose the color(s) of your material"
                        onChange={(e: any) => {
                          if (e.value.trim().length === 20) return;
                          handleBadgeAdditionOnChange(e, "colors");
                        }}
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          handleBadgeAddition(e, "colors");
                        }}
                        options={colors_options}
                      />
                      {createMaterialFormData.colors.map((color, index) => (
                        <Badge
                          as="span"
                          pill
                          className="badge text-dark bg-light mr-2 mb-2"
                          key={index}
                        >
                          {color}
                          <span
                            className="badge-close"
                            onClick={() => removeBadge("colors", index)}
                          >
                            &times;
                          </span>
                        </Badge>
                      ))}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Col md={6}>
                      <Form.Label>Material Characteristics</Form.Label>
                      <Select
                        placeholder="Add characteristics to your material"
                        onChange={(e: any) => {
                          if (e.value.trim().length === 20) return;
                          handleBadgeAdditionOnChange(e, "characteristics");
                        }}
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          handleBadgeAddition(e, "characteristics");
                        }}
                        options={design_characteristics_options}
                      />
                      {createMaterialFormData.characteristics.map(
                        (characteristic, index) => (
                          <Badge
                            as="span"
                            pill
                            className="badge text-dark bg-light mr-2 mb-2"
                            key={index}
                          >
                            {characteristic}
                            <span
                              className="badge-close"
                              onClick={() =>
                                removeBadge("characteristics", index)
                              }
                            >
                              &times;
                            </span>
                          </Badge>
                        )
                      )}
                    </Col>

                    <Col md={6}>
                      <Form.Label>Utility</Form.Label>
                      <Select
                        placeholder="Specify the utility of your material"
                        onChange={(e: any) => {
                          if (e.value.trim().length === 20) return;
                          handleBadgeAdditionOnChange(e, "utility");
                        }}
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          handleBadgeAddition(e, "utility");
                        }}
                        options={utility_options}
                      />
                      {createMaterialFormData.utility.map((utility, index) => (
                        <Badge
                          as="span"
                          pill
                          className="badge text-dark bg-light mr-2 mb-2"
                          key={index}
                        >
                          {utility}
                          <span
                            className="badge-close"
                            onClick={() => removeBadge("utility", index)}
                          >
                            &times;
                          </span>
                        </Badge>
                      ))}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Col className="pt-4">
                      <Form.Label>Material Design Type</Form.Label>
                      <Select
                        placeholder="Enter your material design type"
                        onChange={(e: any) => {
                          if (e.value.trim().length === 20) return;
                          handleBadgeAdditionOnChange(e, "designType");
                        }}
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          handleBadgeAddition(e, "designType");
                        }}
                        options={design_type_options}
                      />
                      {createMaterialFormData.designType.map((type, index) => (
                        <Badge
                          as="span"
                          pill
                          className="badge text-dark bg-light mr-2 mb-2"
                          key={index}
                        >
                          {type}
                          <span
                            className="badge-close"
                            onClick={() => removeBadge("designType", index)}
                          >
                            &times;
                          </span>
                        </Badge>
                      ))}
                    </Col>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="outline-light"
                    className="btn-transparent-invert"
                    type="submit"
                  >
                    Create Material
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        )}
      </Container>
    </>
  );
};

export default MaterialsPage;

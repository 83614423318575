import {
  GlyphID,
  IMaterial,
  MAX_GLYPH_DIM,
  MIN_GLYPH_DIM,
  MaterialID,
  OrganizationID,
  RoleType,
} from "@glyph-platforms/glyph-common";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as glyphAPI from "../api/backend";
import {
  deleteGlyph,
  getGlyphMaterials,
  removeMaterialFromGlyph,
} from "../api/db";
import Alert from "../components/Alert";
import LoadingScreen from "../components/LoadingScreen";
import { useAuth } from "../hooks/useAuth";
import useGlyphListener from "../hooks/useGlyphListener";
import { extractFilenameAndExtension } from "../lib/generic";
import { getDefaultGlyphThumbnail } from "../lib/glyphs";
import { getDefaultThumbnail } from "../lib/materials";

const GlyphViewPage: React.FC = () => {
  const { user, role } = useAuth();
  const { glyphId } = useParams<{ glyphId: GlyphID }>();
  const [glyphMaterials, setGlyphMaterials] = useState<IMaterial[] | null>(
    null
  );
  const hasEditPerms =
    role?.roleType && [RoleType.Admin, RoleType.Editor].includes(role.roleType);
  const [loadingDeleting, setLoadingDeleting] = useState(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "danger" | "success" | "warning" | "info"
  >("danger");
  const navigate = useNavigate();

  const handleEditModalOpen = () => setShowEditModal(true);
  const handleEditModalClose = () => setShowEditModal(false);

  const maxCharacterLimit20 = 20;
  const maxCharacterLimit500 = 500;

  const triggerAlert = (
    message: string,
    type: "danger" | "success" | "warning" | "info"
  ) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEditGlyphFormData((prev) => ({ ...prev, [name]: value }));
  };

  if (!user) {
    triggerAlert("You are not logged in", "danger");
    throw new Error("You are not logged in");
  }

  if (!glyphId) {
    triggerAlert("Glyph ID is required", "danger");
    throw new Error("GlyphID is required");
  }

  if (!role) {
    triggerAlert("You dont have a role", "danger");
    throw new Error("You don't have a role.");
  }

  const { glyph, loading } = useGlyphListener({
    glyphID: glyphId,
    organizationId: role.organizationID,
  });

  useEffect(() => {
    if (!glyph) {
      return;
    }
    getGlyphMaterials(role.organizationID, glyph.id)
      .then((materials) => {
        setGlyphMaterials(materials || []);
      })
      .catch((err) => {
        setGlyphMaterials([]);
        console.error(err);
      });
  }, [glyph, role.organizationID]);

  const onDownload = (imageUrl: string, fileName: string | null) => {
    if (!imageUrl || !fileName) return;

    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  const handleClickDownload = () => {
    if (!glyph || !glyph.patternImageFileSvgURL) return;

    const imageUrl = glyph.patternImageFileSvgURL;
    const fileName = extractFilenameAndExtension(imageUrl);
    onDownload(imageUrl, fileName);
  };

  const handleDeleteGlyph = async (
    organizationId: OrganizationID,
    glyphID: GlyphID
  ): Promise<void> => {
    if (!user || !role) {
      return;
    }

    if (!hasEditPerms) {
      triggerAlert("You do not have permission to delete this Glyph", "danger");
      return;
    }

    const userConfirmed = window.confirm(
      "Do you really want to delete this Glyph?"
    );
    if (!userConfirmed) {
      return;
    }

    setLoadingDeleting(true);

    try {
      await deleteGlyph(organizationId, glyphID);
      triggerAlert("Glyph deleted", "success");
      navigate("/glyphs");
    } catch (err) {
      console.error(err);
      triggerAlert("An error occurred! Please try again later", "danger");
    } finally {
      setLoadingDeleting(false);
    }
  };

  const [editGlyphFormData, setEditGlyphFormData] = useState({
    name: "",
    description: "",
    content: {
      width: 10,
      height: 10,
    },
  });

  const handleEditGlyph = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user || !role || !glyph) {
      return;
    }

    if (!hasEditPerms) {
      triggerAlert("You do not have permission to edit this Glyph", "danger");
      return;
    }

    try {
      await glyphAPI.editGlyph({
        glyphID: glyph.id,
        name: editGlyphFormData.name,
        description: editGlyphFormData.description,
      });
      triggerAlert("Glyph updated", "success");
      setShowEditModal(false);
    } catch (err: any) {
      triggerAlert(err.message, "danger");
    } finally {
      setShowEditModal(false);
    }
  };

  useEffect(() => {
    if (glyph) {
      setEditGlyphFormData({
        name: glyph.name,
        description: glyph.description,
        content: {
          height: glyph.size.height,
          width: glyph.size.width,
        },
      });
    }
  }, [glyph]);

  const handleRemoveMaterialFromGlyph = async (materialID: MaterialID) => {
    if (!glyph) {
      return;
    }
    try {
      await removeMaterialFromGlyph(
        user.id,
        role.organizationID,
        glyph.id,
        materialID
      );
      setGlyphMaterials((materials) => {
        if (!materials) {
          return [];
        }
        return materials.filter((m) => m.id !== materialID);
      });
      triggerAlert("Material removed from Glyph", "success");
    } catch (err) {
      console.error(err);
      triggerAlert("An error occured! Please try again later", "danger");
    }
  };

  const handleMaterialCardClick = (materialId: string) => {
    navigate(`/material-view/${materialId}`);
  };

  const MaterialCard = (material: IMaterial) => {
    const truncatedName = material.name.slice(0, 20);

    const handleDeleteClick = (materialID: MaterialID) => {
      const isConfirmed = window.confirm(
        "Are you sure you want to remove this material from glyph?"
      );
      if (isConfirmed) {
        handleRemoveMaterialFromGlyph(materialID);
      }
    };

    return (
      <Col md={3} className="mb-4">
        <Card className="material-card" style={{ cursor: "pointer" }}>
          <Image
            src={
              getDefaultThumbnail(material)
                ? getDefaultThumbnail(material)
                : "/placeholder-small.png"
            }
            alt="Material Image"
            onClick={() => handleMaterialCardClick(material.id)}
            onError={(e) => (e.currentTarget.src = "placeholder-small.png")}
            rounded
            fluid
          />
          <Card.Body>
            <Card.Title className="d-flex justify-content-between">
              <span>{truncatedName}</span>
            </Card.Title>
            <Card.Text>
              {material.collection &&
                material.collection.map((collection, index) => (
                  <>
                    <Badge key={index} bg="light" text="dark">
                      {collection}
                    </Badge>{" "}
                    &nbsp;
                  </>
                ))}
              <Button
                variant="danger"
                size="sm"
                className="float-right"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteClick(material.id);
                }}
              >
                Remove
              </Button>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const themes = [
    {
      value: 0,
      colors: {
        r: "#00ffff",
        g: "#00ff00",
        b: "#ffff00",
      },
    },
    {
      value: 1,
      colors: {
        r: "#009fe3",
        g: "#320032",
        b: "#c6ff72",
      },
    },
  ];

  const ColorBox: React.FC<{ color: string }> = ({ color }) => (
    <div
      style={{
        width: "30px",
        height: "30px",
        backgroundColor: color,
        display: "inline-block",
        margin: "0 5px",
      }}
    ></div>
  );

  function renderThemeBoxes(themeValue: number) {
    const theme = themes.find((t) => t.value === themeValue);

    if (!theme) return null;

    return (
      <>
        <ColorBox color={theme.colors.r} />
        <ColorBox color={theme.colors.g} />
        <ColorBox color={theme.colors.b} />
      </>
    );
  }

  return (
    <>
      <Alert
        show={showAlert}
        message={alertMessage}
        type={alertType}
        onClose={closeAlert}
      />
      <Container>
        {loading ? (
          <div className="container-for-loading">
            <LoadingScreen displayText="Loading glyph..." />
          </div>
        ) : loadingDeleting ? (
          <div className="container-for-loading">
            <LoadingScreen displayText="Deleting glyph..." />
          </div>
        ) : (
          <>
            <Row className="my-4">
              <Col className="d-flex justify-content-between align-items-center">
                <h3 className="page-heading">Glyph Details</h3>
                <Button
                  onClick={handleEditModalOpen}
                  variant="outline-light"
                  className="btn-transparent-invert"
                >
                  Edit Glyph
                </Button>
              </Col>
            </Row>

            <Row className="text-center">
              <Col md={6} lg={5} xl={4}>
                <Image
                  className="gylph-details-img"
                  src={
                    glyph
                      ? getDefaultGlyphThumbnail(glyph)
                        ? getDefaultGlyphThumbnail(glyph)
                        : "/placeholder-small.png"
                      : ""
                  }
                  rounded
                  fluid
                />
                <br />
                {glyph ? (
                  getDefaultGlyphThumbnail(glyph) ? (
                    <Button
                      className="mt-3 gylph-download-btn btn-transparent-invert"
                      variant="outline-light"
                      onClick={handleClickDownload}
                    >
                      Download
                    </Button>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </Col>
              <Col
                md={6}
                lg={7}
                className="gylph-details-content text-start mt-5 mt-md-0"
              >
                <h3 className="page-text">{glyph ? glyph.name : ""}</h3>
                <p className="page-text">
                  Description: <span>{glyph ? glyph.description : ""}</span>
                </p>
                <p className="page-text">
                  Size:{" "}
                  <span>
                    {glyph && glyph.targetPhysicalSize
                      ? glyph.targetPhysicalSize.width +
                        " x " +
                        glyph.targetPhysicalSize.height
                      : ""}{" "}
                    mm
                  </span>
                </p>
                <p className="page-text">
                  Status:{" "}
                  <span>
                    {" "}
                    {glyph
                      ? glyph.status === "active"
                        ? "Your Glyph is ready to be scanned in the iOS app"
                        : glyph.status === "creating"
                        ? "Please wait while we finalize your Glyph"
                        : glyph.status === "queued"
                        ? "Your Glyph is in the queue to be processed"
                        : "There was an issue creating your Glyph. Please try again later."
                      : ""}
                  </span>
                </p>
                <p className="page-text">
                  Created:{" "}
                  <span>
                    {glyph
                      ? new Date(glyph.timeCreatedUnix).toLocaleString()
                      : ""}
                  </span>
                </p>
                {/* <p className="page-text">Theme: {glyph && typeof glyph.codebookIndex === 'number' ? renderThemeBoxes(glyph.codebookIndex) : ''}</p> */}
              </Col>
            </Row>

            <hr className="my-4 divider" />

            <h3 className="page-heading mb-5">Attached Materials</h3>
            <Row className="sb">
              {glyphMaterials ? (
                glyphMaterials.map((material) => MaterialCard(material))
              ) : (
                <></>
              )}
            </Row>

            <Row>
              <Col md={12} className="my-5 my-md-0">
                <Button
                  variant="outline-danger"
                  style={{ float: "right" }}
                  className="btn-delete-invert"
                  onClick={() =>
                    glyph && handleDeleteGlyph(role.organizationID, glyph.id)
                  }
                >
                  Delete Glyph
                </Button>
              </Col>
            </Row>
            
            <Modal show={showEditModal} onHide={handleEditModalClose}>
              <Modal.Header>
                <Modal.Title>Edit Glyph</Modal.Title>
                <button
                  type="button"
                  className="close modal-close-btn"
                  aria-label="Close"
                  onClick={handleEditModalClose}
                  style={{ color: "white" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Form onSubmit={handleEditGlyph}>
                <Modal.Body>
                  <Form.Group className="mb-3">
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      maxLength={maxCharacterLimit20}
                      placeholder="Enter a name"
                      value={editGlyphFormData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      rows={3}
                      placeholder="Enter a description"
                      maxLength={maxCharacterLimit500}
                      value={editGlyphFormData.description}
                      onChange={handleInputChange}
                    />
                  </Form.Group>

                  <Row className="mb-3">
                    <Col>
                      <Form.Label>Width in Units*</Form.Label>
                      <Form.Control
                        type="number"
                        min={MIN_GLYPH_DIM}
                        max={MAX_GLYPH_DIM}
                        value={editGlyphFormData.content.width}
                        onChange={handleInputChange}
                        placeholder="Enter the width in units"
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Label>Height in Units*</Form.Label>
                      <Form.Control
                        type="number"
                        min={MIN_GLYPH_DIM}
                        max={MAX_GLYPH_DIM}
                        value={editGlyphFormData.content.height}
                        onChange={handleInputChange}
                        placeholder="Enter the height in units"
                        required
                      />
                    </Col>
                  </Row>

                  {/* <Row className="mb-3">
                        <Col>
                        <Form.Label>Width in units:</Form.Label>
                        <Form.Control 
                            type="number"
                            name="width"
                            placeholder='Enter the width in units' 
                            value={editGlyphFormData.content.width} 
                            onChange={handleInputChange}
                            required
                            disabled
                        />
                        </Col>
                        <Col>
                        <Form.Label>Height in units:</Form.Label>
                        <Form.Control 
                            type="number"
                            name="height"
                            placeholder='Enter the height in units'
                            value={editGlyphFormData.content.height}
                            onChange={handleInputChange}
                            required
                            disabled
                        />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                        <Form.Label>Scale in millimeters per pixel:</Form.Label>
                        <Form.Control 
                            type="number"
                            name="scale"
                            placeholder='Enter the scale in millimeters per pixel' 
                            value={editGlyphFormData.scale} 
                            onChange={handleInputChange}
                            required
                        />
                        </Col>
                    </Row> */}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="outline-light"
                    className="btn-transparent-invert"
                    type="submit"
                  >
                    Update Glyph
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        )}
      </Container>
    </>
  );
};

export default GlyphViewPage;

import React, { FunctionComponent, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import LoadingScreen from "../components/LoadingScreen";
import { useAuth } from "../hooks/useAuth";

export type LoginMode = "email-password";
export type AuthFlowType = "login";

interface LoginFormProps {
  onSignOutCallback?: () => void;
  onAuthFlowChange?: (flow: AuthFlowType) => void;
  initFlow?: AuthFlowType;
  buttonText?: string;
  title?: string;
}

const LoginPage: FunctionComponent<LoginFormProps> = (props): JSX.Element => {
  const { signInWithEmailAndPassword } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "danger" | "success" | "warning" | "info"
  >("danger");

  const triggerAlert = (
    message: string,
    type: "danger" | "success" | "warning" | "info"
  ) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  const handleLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(email, password);
      setLoading(false);
      navigate("/materials");
    } catch (err) {
      triggerAlert(`Error logging in: ${err}`, "danger");
      setLoading(false);
    } finally {
      return;
    }
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    await handleLogin(formData);
  };
  return (
    <div className="login-page-container">
      <Alert
        show={showAlert}
        message={alertMessage}
        type={alertType}
        onClose={closeAlert}
      />
      <Container fluid className="h-100">
        <Row className="justify-content-center align-items-center h-100">
          <Col xs={12} md={6} lg={4}>
            {loading ? (
              <LoadingScreen displayText="Logging in..." />
            ) : (
              <Card className="login-card">
                <Card.Body className="text-center">
                  <Card.Title>
                    <img
                      src="/images/logo.svg"
                      alt="Profile"
                      width="80"
                      className="rounded-circle"
                    />
                    {/* <h1 className='page-heading'>Login</h1> */}
                  </Card.Title>
                  <Form onSubmit={handleSubmit} className="login-form">
                    <FormGroup>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }))
                        }
                        required
                      />
                    </FormGroup>

                    <FormGroup>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={(e) =>
                          setFormData((prev) => ({
                            ...prev,
                            password: e.target.value,
                          }))
                        }
                        required
                      />
                    </FormGroup>
                    <br />
                    <div style={{ display: "block" }}>
                      <Button
                        className="btn-parrot"
                        type="submit"
                        style={{ float: "right" }}
                      >
                        Sign in
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;

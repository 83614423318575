import { glyphAPI } from './glyphAPI';
import {
  CreateGlyphRequestBody,
  EditGlyphRequestBody,
  Glyph,
  GlyphID,
} from '@glyph-platforms/glyph-common';

export interface CreateGlyphPayload {
  name: string;
  description: string;
  size: { width: number; height: number };
  scale: number;
  codebookColor: number;
}

export const createGlyph = async (payload: CreateGlyphPayload) => {
  const res = await glyphAPI<Glyph, CreateGlyphRequestBody>('glyphs/create', {
    method: 'POST',
    body: payload,
  });

  return res;
};

export interface EditGlyphPayload {
  glyphID: GlyphID;
  name?: string;
  description?: string;
}
export const editGlyph = async (payload: EditGlyphPayload) => {
  const { glyphID, ...rest } = payload;
  const res = await glyphAPI<Glyph, EditGlyphRequestBody>(
    `glyphs/edit/${glyphID}`,
    {
      method: 'PATCH',
      body: rest,
    }
  );

  return res;
};
import React, { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import LoadingScreen from '../LoadingScreen';

type RequireAuthProps = {
  redirectTo: string;
  children: JSX.Element;
};

const RequireAuth: FunctionComponent<RequireAuthProps> = ({
  children,
  redirectTo,
}): JSX.Element => {
  const { user } = useAuth();

  if (user === undefined) {
    // Loading
    return (
      <div className="container-for-loading">
        <LoadingScreen displayText='Loading...'/>
      </div>
    );
  }

  return user ? children : <Navigate to={redirectTo} />;
};

export default RequireAuth;

import React, { CSSProperties, useEffect, useRef, useState } from "react";

interface AlertProps {
  show: boolean;
  message: string;
  type: "danger" | "success" | "warning" | "info";
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({ show, message, type, onClose }) => {
  const [display, setDisplay] = useState(show);
  const alertRef = useRef<HTMLDivElement | null>(null);

  const alertStyles: CSSProperties = {
    maxWidth: "500px",
    margin: "2rem auto",
    position: "fixed",
    left: "50%",
    top: "10%",
    transform: "translateX(-50%)",
    zIndex: 2000,
  };

  const closeButtonStyle = {
    lineHeight: "1",
    cursor: "pointer",
    border: "none",
    backgroundColor: "transparent",
    fontSize: "1.5rem",
    paddingLeft: "5px",
    marginTop: "-5px",
    opacity: 0.7,
  };

  useEffect(() => {
    if (show) {
      setDisplay(true);
    }
  }, [show]);

  useEffect(() => {
    const node = alertRef.current;
    if (node) {
      node.addEventListener("transitionend", () => {
        if (!show) {
          setDisplay(false);
        }
      });
    }

    return () => {
      if (node) {
        node.removeEventListener("transitionend", () => {});
      }
    };
  }, [show]);

  if (!display) {
    return null;
  }

  return (
    <div
      className={`alert alert-${type} alert-dismissible fade ${
        show ? "show" : ""
      }`}
      role="alert"
      style={alertStyles}
      ref={alertRef}
    >
      <div className="text-center">
        <span>{message}</span>
        <button
          type="button"
          aria-label="Close"
          style={closeButtonStyle}
          onClick={onClose}
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default Alert;

import {
  Glyph,
  GlyphID,
  IMaterial,
  MaterialFileType,
  MaterialVisibility,
  OrganizationID,
  RoleType,
  StorageRoutes,
  ThumbnailSize,
} from "@glyph-platforms/glyph-common";
import { ref, uploadBytes } from "firebase/storage";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { createRecord } from "../api/airtable";
import {
  UpdateMaterialArgs,
  deleteMaterial,
  getGlyph,
  removeMaterialFromGlyph,
  updateMaterial,
} from "../api/db";
import { auth, storage } from "../api/firebase";
import { generateImages } from "../api/image-gen";
import Alert from "../components/Alert";
import LoadingScreen from "../components/LoadingScreen";
import { useAuth } from "../hooks/useAuth";
import useMaterialListener from "../hooks/useMaterialListener";
import { extractFilenameAndExtension } from "../lib/generic";
import { getDefaultGlyphThumbnail } from "../lib/glyphs";
import { getDefaultThumbnail } from "../lib/materials";

// Omporting all Dropdown Value

const colors_options = [
  { value: "Black", label: "Black" },
  { value: "Blue", label: "Blue" },
  { value: "Brown", label: "Brown" },
  { value: "Cyan", label: "Cyan" },
  { value: "Gold", label: "Gold" },
  { value: "Gray", label: "Gray" },
  { value: "Green", label: "Green" },
  { value: "Magenta", label: "Magenta" },
  { value: "Orange", label: "Orange" },
  { value: "Pink", label: "Pink" },
  { value: "Purple", label: "Purple" },
  { value: "Red", label: "Red" },
  { value: "Silver", label: "Silver" },
  { value: "White", label: "White" },
  { value: "Yellow", label: "Yellow" },
];

const utility_options = [
  { value: "Accessories", label: "Accessories" },
  { value: "Art", label: "Art" },
  { value: "Apparel", label: "Apparel" },
  { value: "Bedding", label: "Bedding" },
  { value: "Upholstery", label: "Upholstery" },
  { value: "Wall-covering", label: "Wall-covering" },
  { value: "Doors", label: "Doors" },
];

const design_characteristics_options = [
  { value: "Prismatic Plates", label: "Prismatic Plates" },
  { value: "Nightsteel Strands", label: "Nightsteel Strands" },
  { value: "Light Vibrant Streams", label: "Light Vibrant Streams" },
  { value: "Omniscient Interface", label: "Omniscient Interface" },
  { value: "Chromatic Resting", label: "Chromatic Resting" },
  { value: "Light Cosmic Kaleidoscope", label: "Light Cosmic Kaleidoscope" },
  { value: "Dark Cosmic Kaleidoscope", label: "Dark Cosmic Kaleidoscope" },
  { value: "Mystical Mosaic", label: "Mystical Mosaic" },
  { value: "Celestial Halo", label: "Celestial Halo" },
  { value: "Whispering Finish", label: "Whispering Finish" },
  { value: "Frosty Glaze", label: "Frosty Glaze" },
  { value: "Misty Veil", label: "Misty Veil" },
  { value: "Mossy Mirage", label: "Mossy Mirage" },
  { value: "Swirling Abyss", label: "Swirling Abyss" },
  { value: "Cobalt Calm", label: "Cobalt Calm" },
  { value: "Petrified Forms", label: "Petrified Forms" },
  { value: "Enlivening Jade", label: "Enlivening Jade" },
  { value: "Serene Smoothness", label: "Serene Smoothness" },
  { value: "Foggy Enigma", label: "Foggy Enigma" },
  { value: "Rustic Patina", label: "Rustic Patina" },
  { value: "Lunar soil", label: "Lunar soil" },
  { value: "Noir Glitches", label: "Noir Glitches" },
  { value: "Mirage Horizons", label: "Mirage Horizons" },
  { value: "Majestic Fog", label: "Majestic Fog" },
  { value: "Enigmatic Chalk", label: "Enigmatic Chalk" },
];

const design_type_options = [
  { value: "Space Colony", label: "Space Colony" },
  {
    value: "Archaeology of Future's Past",
    label: "Archaeology of Future's Past",
  },
  { value: "Multiworld Rave ", label: "Multiworld Rave " },
  { value: "Infinite Sanctuary", label: "Infinite Sanctuary" },
  { value: "Augmented Nature", label: "Augmented Nature" },
  { value: "Floral", label: "Floral" },
  { value: "Abstract", label: "Abstract" },
  { value: "Animal", label: "Animal" },
  { value: "Botanical", label: "Botanical" },
  { value: "Checkered", label: "Checkered" },
  { value: "Geometric", label: "Geometric" },
  { value: "Chipboard Wood", label: "Chipboard Wood" },
  { value: "Fiberboard Wood", label: "Fiberboard Wood" },
  { value: "OSB Wood", label: "OSB Wood" },
  { value: "Oak Wood", label: "Oak Wood" },
  { value: "Balsa Wood", label: "Balsa Wood" },
  { value: "Maple Wood", label: "Maple Wood" },
  { value: "Pine Wood", label: "Pine Wood" },
  { value: "Teak Wood", label: "Teak Wood" },
  { value: "Walnut Wood", label: "Walnut Wood" },
  { value: "Cherry Wood", label: "Cherry Wood" },
  { value: "Birch Wood", label: "Birch Wood" },
  { value: "Redwood", label: "Redwood" },
  { value: "Ash Wood", label: "Ash Wood" },
  { value: "Cedar Wood", label: "Cedar Wood" },
  { value: "Mahogany Wood", label: "Mahogany Wood" },
  { value: "Rosewood", label: "Rosewood" },
  { value: "SandStone", label: "SandStone" },
  { value: "Tuff Stone", label: "Tuff Stone" },
  { value: "White Sand", label: "White Sand" },
  {
    value: "Bianco Carrara Marble Stone",
    label: "Bianco Carrara Marble Stone",
  },
  { value: "Travertine Marble Stone", label: "Travertine Marble Stone" },
  { value: "Pentelikon Marble Stone", label: "Pentelikon Marble Stone" },
  { value: "Granite Stone", label: "Granite Stone" },
  { value: "Limestone", label: "Limestone" },
  { value: "Slate Stone", label: "Slate Stone" },
  { value: "Basalt Stone", label: "Basalt Stone" },
  { value: "Onyx Stone", label: "Onyx Stone" },
  { value: "Obsidian Stone", label: "Obsidian Stone" },
  { value: "Rusted Iron Metal", label: "Rusted Iron Metal" },
  { value: "Copper Metal", label: "Copper Metal" },
  { value: "Tarnished Silver Metal", label: "Tarnished Silver Metal" },
  { value: "Gold Foil Metal", label: "Gold Foil Metal" },
  { value: "Zinc Metal", label: "Zinc Metal" },
  { value: "Cotton Wall Fabric", label: "Cotton Wall Fabric" },
  { value: "Silk Wall Fabric", label: "Silk Wall Fabric" },
  { value: "Denim Wall Fabric", label: "Denim Wall Fabric" },
  { value: "Velvet Wall Fabric", label: "Velvet Wall Fabric" },
  { value: "Wool Wall Fabric", label: "Wool Wall Fabric" },
  { value: "White Porcelain", label: "White Porcelain" },
  { value: "Celadon Porcelain", label: "Celadon Porcelain" },
  { value: "Rose Porcelain", label: "Rose Porcelain" },
  { value: "Jade Green Porcelain", label: "Jade Green Porcelain" },
];

const PUBLIC_TAG: MaterialVisibility = "public";
const PRIVATE_TAG: MaterialVisibility = "private";

const generateDiffuseImagePrompt = (
  designType: string[],
  colors: string[],
  characteristics: string[]
): string => {
  const DEFAULT_DESIGN_TYPE = "fabric";
  const DEFAULT_COLOR = "blue";
  const DEFAULT_CHARACTERISTICS = "smooth";
  const prompt =
    `A diffuse map of "${
      designType.length ? designType.join(", ") : DEFAULT_DESIGN_TYPE
    }" material texture ` +
    `with a "${
      colors.length ? colors.join(", ") : DEFAULT_COLOR
    }" color, and ` +
    `"${
      characteristics.length
        ? characteristics.join(", ")
        : DEFAULT_CHARACTERISTICS
    }" characteristics.`;

  return prompt;
};

const MaterialViewPage: React.FC = () => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [loadingDeleting, setLoadingDeleting] = useState(false);
  const { materialId } = useParams();
  const { user, role } = useAuth();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "danger" | "success" | "warning" | "info"
  >("danger");
  const [loadingImageGen, setLoadingImageGen] = useState(false);
  const [showConfirmImageModal, setShowConfirmImageModal] = useState(false);
  const [generatedB64Image, setGeneratedB64Image] = useState<string | null>(
    null
  );
  const hasEditPerms =
    role?.roleType && [RoleType.Admin, RoleType.Editor].includes(role.roleType);
  const [materialGlyphs, setMaterialGlyphs] = useState<Glyph[] | null>(null);
  const [diffuseImagePromp, setDiffuseImagePromp] = useState("");
  const [uploadToAirTable, setUploadToAirTable] = useState<{
    prompt: string;
  } | null>(null);
  const previousMaterialRef = useRef<IMaterial>();
  const diffuseInputRef = useRef<HTMLInputElement>(null);
  const normalMapInputRef = useRef<HTMLInputElement>(null);
  const specularMapInputRef = useRef<HTMLInputElement>(null);
  const thumbnailInputRef = useRef<HTMLInputElement>(null);
  const metallicMapInputRef = useRef<HTMLInputElement>(null);
  const displacementMapInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleEditModalOpen = () => setShowEditModal(true);
  const handleEditModalClose = () => setShowEditModal(false);

  const maxCharacterLimit20 = 20;
  const maxCharacterLimit500 = 500;

  const triggerAlert = (
    message: string,
    type: "danger" | "success" | "warning" | "info"
  ) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  if (!user) {
    throw new Error("You are not logged in");
  }

  if (!materialId) {
    throw new Error("Material ID is required");
  }

  if (!role) {
    throw new Error("You are not associated to an organization");
  }

  const { material, loading } = useMaterialListener({
    materialID: materialId,
    organizationId: role.organizationID,
  });

  useEffect(() => {
    // Update airtable async because it needs a public url to the file
    if (
      uploadToAirTable &&
      material?.files?.diffuse?.url &&
      material?.files?.diffuse?.url !==
        previousMaterialRef.current?.files?.diffuse?.url
    ) {
      // save the diffuse image with the prompt to airtable
      console.log("### Uploading to airtable ### ");
      createRecord({
        materialID: material.id,
        prompt: uploadToAirTable.prompt,
        imageURL: material.files.diffuse.url,
      })
        .then((res) => {
          console.log("success!");
        })
        .catch((err) => {
          console.error("Error uploading to airtable", err?.message);
        })
        .finally(() => {
          // remove the upload to airtable prompt
          setUploadToAirTable(null);
        });
    }
    previousMaterialRef.current = material ? material : undefined; // Capture the current value for the next render
  }, [material, uploadToAirTable]);

  const thumbnailImage = material
    ? getDefaultThumbnail(material, ThumbnailSize.lg)
    : "default-material-image.png";
  const downloadImage = material ? material.files.diffuse?.url : undefined;

  const handleDeleteMaterial = async (
    organizationId: OrganizationID,
    materialID: GlyphID
  ): Promise<void> => {
    if (!user || !role) {
      return;
    }

    if (!hasEditPerms) {
      triggerAlert(
        "You do not have permission to delete this Material.",
        "danger"
      );
      return;
    }

    const userConfirmed = window.confirm(
      "Do you really want to delete this Material?"
    );

    if (!userConfirmed) {
      return;
    }

    setLoadingDeleting(true);

    try {
      await deleteMaterial(organizationId, materialID);
      triggerAlert("Material deleted.", "success");

      navigate("/materials");
    } catch (err) {
      console.error(err);
      triggerAlert("An error occurred! Please try again later.", "danger");
    } finally {
      setLoadingDeleting(false);
    }
  };

  const onDownload = (imageUrl: string, fileName: string | null) => {
    if (!imageUrl || !fileName) return;

    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  const handleClickDownload = () => {
    if (!material || !downloadImage) return;

    const imageUrl = downloadImage;
    const fileName = extractFilenameAndExtension(downloadImage);
    onDownload(imageUrl, fileName);
  };

  const handleEditMaterial = async (data: UpdateMaterialArgs) => {
    if (!user || !role || !material) {
      return;
    }
    if (!hasEditPerms) {
      triggerAlert(
        "You do not have permission to edit this material.",
        "danger"
      );
      return;
    }
    try {
      await updateMaterial(role.organizationID, material.id, data, material);
      triggerAlert("Material updated.", "success");
    } catch (err: any) {
      triggerAlert(err.message, "danger");
    } finally {
      handleEditModalClose();
    }
  };

  const toggleImageModal = (value: boolean | undefined = undefined) => {
    setShowConfirmImageModal(
      value === undefined ? !showConfirmImageModal : value
    );
  };

  const handleGenerateDiffuseButtonClick = async () => {
    if (!generatedB64Image || showConfirmImageModal) {
      return generateDiffuseImage();
    } else {
      return toggleImageModal();
    }
  };

  const generateDiffuseImage = async () => {
    if (!user || !role || !material) {
      return;
    }

    // A diffuse map of ${DesignType} material texture, with a “${Color}“ color, and “${characteristics}”
    // characteristics
    const prompt = diffuseImagePromp
      ? diffuseImagePromp
      : generateDiffuseImagePrompt(
          material?.designType || [],
          material?.colors || [],
          material?.characteristics || []
        );
    if (!diffuseImagePromp) {
      setDiffuseImagePromp(prompt);
    }

    setLoadingImageGen(true);
    try {
      const imageURL = await generateImages({ promptText: prompt });
      setGeneratedB64Image(imageURL);
      toggleImageModal(true);
    } catch (err) {
      triggerAlert("An error occurred! Please try again later.", "danger");
    } finally {
      setLoadingImageGen(false);
    }
  };

  const [editMaterialFormData, setEditMaterialFormData] =
    useState<UpdateMaterialArgs>({
      name: "",
      description: "",
      colors: [],
      scale: { width: 0, height: 0 },
      price: {
        value: 0,
        currency: "",
        precision: 0,
      },
      collection: [],
      characteristics: [],
      utility: [],
      designType: [],
      visibility: PUBLIC_TAG,
    });
  useEffect(() => {
    if (material) {
      setEditMaterialFormData({
        name: material.name,
        description: material.description,
        colors: material.colors,
        scale: material.scale,
        price: material.price,
        collection: material.collection,
        characteristics: material.characteristics,
        utility: material.utility,
        designType: material.designType,
        visibility: material.visibility,
      });
    }
  }, [material]);
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    key: keyof UpdateMaterialArgs | string
  ) => {
    let value: any = e.target.value;

    if (
      [
        "collection",
        "colors",
        "utility",
        "designType",
        "characteristics",
      ].includes(key as string)
    ) {
      value = value.split(",").map((item: string) => item.trim());
    }

    if (key === "scale.width" || key === "scale.height") {
      value = Number(value);
    }

    if (key === "scale.width") {
      setEditMaterialFormData((prevState) => ({
        ...prevState,
        scale: {
          ...prevState.scale,
          width: value,
        },
      }));
    } else if (key === "scale.height") {
      setEditMaterialFormData((prevState) => ({
        ...prevState,
        scale: {
          ...prevState.scale,
          height: value,
        },
      }));
    } else {
      setEditMaterialFormData((prevState) => ({
        ...prevState,
        [key as keyof UpdateMaterialArgs]: value,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleEditMaterial(editMaterialFormData);
  };

  const handleBadgeAddition = (
    e: React.KeyboardEvent<HTMLInputElement>,
    key: keyof UpdateMaterialArgs
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();

      let updatedValue: string[] = [];

      if (Array.isArray(editMaterialFormData[key])) {
        updatedValue = [
          ...(editMaterialFormData[key] as string[]),
          e.currentTarget.value,
        ];
      }

      setEditMaterialFormData((prevState) => ({
        ...prevState,
        [key]: updatedValue,
      }));

      e.currentTarget.value = "";
    }
  };

  const handleBadgeAdditionSelect = (
    e: React.KeyboardEvent<HTMLInputElement>,
    key: keyof UpdateMaterialArgs
  ) => {
    // @ts-ignore
    if (e.target.value?.length < 11) {
      if (e.key === "Enter") {
        e.preventDefault();

        let updatedValue: string[] = [];

        if (Array.isArray(editMaterialFormData[key])) {
          updatedValue = [
            ...(editMaterialFormData[key] as string[]),
            // @ts-ignore
            e.target?.value,
          ];
        }

        setEditMaterialFormData((prevState) => ({
          ...prevState,
          [key]: updatedValue,
        }));
      }
    }
  };

  const handleBadgeAdditionSelectOnchange = (
    e: any,
    key: keyof UpdateMaterialArgs
  ) => {
    let updatedValue: string[] = [];
    if (Array.isArray(editMaterialFormData[key])) {
      updatedValue = [
        ...(editMaterialFormData[key] as string[]),
        // @ts-ignore
        e.value,
      ];
    }

    setEditMaterialFormData((prevState) => ({
      ...prevState,
      [key]: updatedValue,
    }));
  };

  const handleBadgeDeletion = (
    valueToDelete: string,
    key: keyof UpdateMaterialArgs
  ) => {
    const values = editMaterialFormData[key];
    let updatedValue: string[] = [];

    if (Array.isArray(values)) {
      updatedValue = values.filter((value) => value !== valueToDelete);
    }

    setEditMaterialFormData((prevState) => ({
      ...prevState,
      [key]: updatedValue,
    }));
  };

  const uploadFile = async (
    file: File | string,
    fileType: MaterialFileType
  ) => {
    if (!auth.currentUser) {
      throw new Error("You are not logged in");
    }

    const filename =
      typeof file === "string"
        ? Date.now() + "-" + file.split("/").pop()
        : Date.now() + "-" + file.name;
    const bucketPath = `${StorageRoutes.Organizations}/${role.organizationID}/${StorageRoutes.Materials}/${materialId}/${StorageRoutes.Files}`;

    const storageRef = ref(storage, bucketPath);

    const fileRef = ref(storageRef, filename);

    const userToken = await auth.currentUser.getIdToken(true);
    const customMetadata = {
      customMetadata: {
        materialFileType: fileType,
        uploaderUserToken: userToken,
      },
    };

    let fileData: File | Blob;

    if (typeof file === "string") {
      // Base 64 encoded image
      const base64Data = file.split(";base64,")[1]; // extract the base64 data
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      fileData = new Blob([byteArray], { type: "image/png" });
    } else {
      fileData = file;
    }

    await uploadBytes(fileRef, fileData, customMetadata);

    return;
  };

  const handleGeneratedDiffuseImageUpload = async (imageURL: string) => {
    triggerAlert(
      "Uploading file... If your file is very large, it may take a few minutes. Do not leave this page.",
      "warning"
    );

    try {
      await uploadFile(imageURL, MaterialFileType.Diffuse);
      setUploadToAirTable({ prompt: diffuseImagePromp });
      triggerAlert(
        `The image was uploaded successfully. It will appear soon.`,
        "success"
      );
      toggleImageModal();
    } catch (error) {
      triggerAlert(
        `There was an issue uploading the image. Please try again.`,
        "danger"
      );
    }
  };

  const handleFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    fileType: MaterialFileType
  ) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const file = e.target.files[0];

    triggerAlert(
      "Uploading file... If your file is very large, it may take a few minutes. Do not leave this page.",
      "warning"
    );

    try {
      await uploadFile(file, fileType);
      triggerAlert(`The ${fileType} was uploaded successfully.`, "success");
    } catch (error) {
      triggerAlert(
        `There was an issue uploading the ${fileType}. Please try again.`,
        "danger"
      );
    }
  };

  const handleDiffuseImagePromptChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDiffuseImagePromp(event.target.value);
    },
    []
  );

  const activateFileInput = (ref: React.RefObject<HTMLInputElement>) => {
    if (ref.current) {
      ref.current.click();
    }
  };

  useEffect(() => {
    if (!material) {
      return;
    }
    const glyphIDs = material && material.glyphIDs;
    if (glyphIDs && glyphIDs.length > 0) {
      const uniqueGlyphIDs = [...new Set(glyphIDs)];
      Promise.all(uniqueGlyphIDs.map((id) => getGlyph(role.organizationID, id)))
        .then((glyphs) => {
          setMaterialGlyphs(glyphs.filter((g) => g !== null) as Glyph[]);
        })
        .catch((err) => {
          setMaterialGlyphs([]);
          console.error(err);
        });
    } else {
      setMaterialGlyphs([]);
    }
  }, [material, role.organizationID]);

  const getBadgeProperties = (
    status: "queued" | "creating" | "active" | "error"
  ) => {
    switch (status) {
      case "queued":
        return { color: "info", text: "Queued" };
      case "creating":
        return { color: "warning", text: "Creating" };
      case "active":
        return { color: "active", text: "Active" };
      case "error":
      default:
        return { color: "inactive", text: "Error" };
    }
  };

  const GlyphCard = (glyph: Glyph) => {
    const truncatedDescription = glyph.description
      ? glyph.description.slice(0, 100)
      : "No description";
    const truncatedName = glyph.name.slice(0, 20);
    const { color, text } = getBadgeProperties(glyph.status);

    const handleDeleteClick = (glyphID: GlyphID) => {
      const isConfirmed = window.confirm(
        "Are you sure you want to remove this glyph from material?"
      );
      if (isConfirmed) {
        handleRemoveGlyphFromMaterial(glyphID);
      }
    };

    return (
      <Col md={3} className="mb-4">
        <Card
          className="glyph-card"
          onClick={() => handleGlyphCardClick(glyph.id)}
          style={{ cursor: "pointer" }}
        >
          <Card.Header className="glyph-card-header">
            {truncatedName}
          </Card.Header>
          <Card.Body className="glyph-card-body">
            <Row>
              <Col xs={4}>
                <img
                  src={getDefaultGlyphThumbnail(glyph)}
                  alt="Glyph"
                  style={{ width: "80px", height: "80px", objectFit: "cover" }}
                />
              </Col>
              <Col xs={8}>
                <Card.Text> {truncatedDescription}</Card.Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Badge pill bg={color} className="mt-2">
                  {text}
                </Badge>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="danger"
                  size="sm"
                  className="float-right m-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(glyph.id);
                  }}
                >
                  Remove Glyph
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const handleGlyphCardClick = (glyphId: string) => {
    navigate(`/glyph-view/${glyphId}`);
  };

  const handleRemoveGlyphFromMaterial = async (glyphID: GlyphID) => {
    if (!material) {
      return;
    }
    try {
      await removeMaterialFromGlyph(
        user.id,
        role.organizationID,
        glyphID,
        material.id
      );
      setMaterialGlyphs((glyphs) => {
        if (!glyphs) {
          return [];
        }
        return glyphs.filter((m) => m.id !== glyphID);
      });
      triggerAlert("Glyph removed from material", "success");
    } catch (err) {
      triggerAlert("An error occured! Please try again later", "danger");
    }
  };

  return (
    <>
      <Alert
        show={showAlert}
        message={alertMessage}
        type={alertType}
        onClose={closeAlert}
      />

      <Container>
        {loading ? (
          <div className="container-for-loading">
            <LoadingScreen displayText="Loading material..." />
          </div>
        ) : loadingDeleting ? (
          <div className="container-for-loading">
            <LoadingScreen displayText="Deleting material..." />
          </div>
        ) : (
          <>
            <Row>
              <Col md={4} className="material-details-content">
                <h2 className="page-heading">
                  {material ? material.name : ""}
                </h2>
                <p className="page-text">
                  Description:{" "}
                  <span>{material ? material.description : ""}</span>
                </p>
                <p className="page-text">
                  Collection:{" "}
                  <span>
                    {material
                      ? material.collection &&
                        material.collection.map((collection, index) => (
                          <>
                            <Badge key={index} bg="light" text="dark">
                              {collection}
                            </Badge>{" "}
                            &nbsp;
                          </>
                        ))
                      : ""}
                  </span>
                </p>
                <p className="page-text">
                  Colors:{" "}
                  <span>
                    {material
                      ? material.colors &&
                        material.colors.map((color, index) => (
                          <>
                            <Badge key={index} bg="light" text="dark">
                              {color}
                            </Badge>{" "}
                            &nbsp;
                          </>
                        ))
                      : ""}
                  </span>
                </p>
                <p className="page-text">
                  Design Type:
                  <span>
                    {material
                      ? material.designType &&
                        material.designType.map((designType, index) => (
                          <>
                            <Badge key={index} bg="light" text="dark">
                              {designType}
                            </Badge>
                            &nbsp;
                          </>
                        ))
                      : ""}
                  </span>
                </p>
                <p className="page-text">
                  Characteristics:{" "}
                  <span>
                    {material
                      ? material.characteristics &&
                        material.characteristics.map(
                          (characteristic, index) => (
                            <>
                              <Badge key={index} bg="light" text="dark">
                                {characteristic}
                              </Badge>{" "}
                              &nbsp;
                            </>
                          )
                        )
                      : ""}
                  </span>
                </p>

                <p className="page-text">
                  Utility:{" "}
                  <span>
                    {material
                      ? material.utility &&
                        material.utility.map((utility, index) => (
                          <>
                            <Badge key={index} bg="light" text="dark">
                              {utility}
                            </Badge>{" "}
                            &nbsp;
                          </>
                        ))
                      : ""}
                  </span>
                </p>
                <p className="page-text">
                  Visibility: <span>{material ? material.visibility : ""}</span>
                </p>
                <p className="page-text">
                  Scale:{" "}
                  <span>
                    {material
                      ? material.scale.width + " x " + material.scale.height
                      : ""}
                  </span>
                </p>
                <p className="page-text">
                  Created: <span>{material ? material.timeCreated : ""}</span>
                </p>
                <div className="material-details-btn">
                  <Button
                    onClick={handleEditModalOpen}
                    variant="outline-light"
                    className="btn-transparent-invert"
                    style={{ width: 200 }}
                  >
                    Edit Material
                  </Button>
                  <br />
                  <Button
                    disabled={loadingImageGen}
                    variant="outline-light"
                    className="mt-3 btn-transparent-invert btn-padding-overide"
                    onClick={handleGenerateDiffuseButtonClick}
                    style={{ width: 200 }}
                  >
                    {loadingImageGen ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        &nbsp;
                        <span className="sr-only">Loading...</span>
                      </>
                    ) : (
                      "Generate Material"
                    )}
                  </Button>
                  <br />
                  {thumbnailImage ? (
                    <Button
                      className="mt-3 btn-transparent-invert"
                      variant="outline-light"
                      onClick={handleClickDownload}
                      style={{ width: 200 }}
                    >
                      Download
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
              <Col md={8}>
                <div className="text-center material-details-img">
                  <Image
                    src={
                      thumbnailImage
                        ? thumbnailImage
                        : "../images/placeholder-small.png"
                    }
                    fluid
                    onError={(e) =>
                      (e.currentTarget.src = "../images/placeholder-small.png")
                    }
                  />
                </div>
              </Col>
            </Row>

            <hr
              style={{
                color: "#FFFFFF",
                backgroundColor: "#FFFFFF",
                height: 1,
                borderColor: "#FFFFFF",
                margin: 0,
              }}
            />

            <hr className="my-4" />
            <h3 className="page-heading">Uploaded Materials</h3>
            <Row className="mt-4">
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="page-text">
                    Diffuse Image (required)
                  </Form.Label>
                  <Button
                    variant="secondary"
                    className="w-100 material-upload-btn"
                    onClick={() => activateFileInput(diffuseInputRef)}
                  >
                    Upload File <Image src={"../images/fileicon.svg"} />
                  </Button>
                  <Form.Control
                    ref={diffuseInputRef}
                    type="file"
                    className="d-none"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFileUpload(e, MaterialFileType.Diffuse)
                    }
                  />
                  {material ? (
                    material.files.diffuse ? (
                      <div
                        style={{
                          border: "3px dotted white",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="m-1"
                      >
                        <Image
                          style={{
                            width: "50px",
                            height: "50px",
                            margin: "5px",
                          }}
                          fluid
                          src={material.files.diffuse.url}
                        />
                      </div>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="page-text">Normal Map</Form.Label>
                  <Button
                    variant="secondary"
                    className="w-100 material-upload-btn"
                    onClick={() => activateFileInput(normalMapInputRef)}
                  >
                    Upload File <Image src={"../images/fileicon.svg"} />
                  </Button>
                  <Form.Control
                    ref={normalMapInputRef}
                    type="file"
                    className="d-none"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFileUpload(e, MaterialFileType.Normal)
                    }
                  />
                  {material ? (
                    material.files.normal ? (
                      <div
                        style={{
                          border: "3px dotted white",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="m-1"
                      >
                        <Image
                          style={{
                            width: "50px",
                            height: "50px",
                            margin: "5px",
                          }}
                          fluid
                          src={material.files.normal.url}
                        />
                      </div>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Label className="page-text">Specular Map</Form.Label>
                  <Button
                    variant="secondary"
                    className="w-100 material-upload-btn"
                    onClick={() => activateFileInput(specularMapInputRef)}
                  >
                    Upload File <Image src={"../images/fileicon.svg"} />
                  </Button>
                  <Form.Control
                    ref={specularMapInputRef}
                    type="file"
                    className="d-none"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFileUpload(e, MaterialFileType.Specular)
                    }
                  />
                  {material ? (
                    material.files.specular ? (
                      <div
                        style={{
                          border: "3px dotted white",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="m-1"
                      >
                        <Image
                          style={{
                            width: "50px",
                            height: "50px",
                            margin: "5px",
                          }}
                          fluid
                          src={material.files.specular.url}
                        />
                      </div>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group>
                  <Form.Label className="page-text">Thumbnail</Form.Label>
                  <Button
                    variant="secondary"
                    className="w-100 material-upload-btn"
                    onClick={() => activateFileInput(thumbnailInputRef)}
                  >
                    Upload File <Image src={"../images/fileicon.svg"} />
                  </Button>
                  <Form.Control
                    ref={thumbnailInputRef}
                    type="file"
                    className="d-none"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFileUpload(e, MaterialFileType.Thumbnail)
                    }
                  />

                  {material ? (
                    material.files.thumbnail ? (
                      <div
                        style={{
                          border: "3px dotted white",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="m-1"
                      >
                        <Image
                          style={{
                            width: "50px",
                            height: "50px",
                            margin: "5px",
                          }}
                          fluid
                          src={material.files.thumbnail.url}
                        />
                      </div>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Label className="page-text">Metallic Map</Form.Label>
                  <Button
                    variant="secondary"
                    className="w-100 material-upload-btn"
                    onClick={() => activateFileInput(metallicMapInputRef)}
                  >
                    Upload File <Image src={"../images/fileicon.svg"} />
                  </Button>
                  <Form.Control
                    ref={metallicMapInputRef}
                    type="file"
                    className="d-none"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFileUpload(e, MaterialFileType.Metallic)
                    }
                  />
                  {material ? (
                    material.files.metallic ? (
                      <div
                        style={{
                          border: "3px dotted white",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="m-1"
                      >
                        <Image
                          style={{
                            width: "50px",
                            height: "50px",
                            margin: "5px",
                          }}
                          fluid
                          src={material.files.metallic.url}
                        />
                      </div>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Label className="page-text">
                    Displacement Map
                  </Form.Label>
                  <Button
                    variant="secondary"
                    className="w-100 material-upload-btn"
                    onClick={() => activateFileInput(displacementMapInputRef)}
                  >
                    Upload File <Image src={"../images/fileicon.svg"} />
                  </Button>
                  <Form.Control
                    ref={displacementMapInputRef}
                    type="file"
                    className="d-none"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleFileUpload(e, MaterialFileType.Displacement)
                    }
                  />
                  {material ? (
                    material.files.displacement ? (
                      <div
                        style={{
                          border: "3px dotted white",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="m-1"
                      >
                        <Image
                          style={{
                            width: "50px",
                            height: "50px",
                            margin: "5px",
                          }}
                          fluid
                          src={material.files.displacement.url}
                        />
                      </div>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <hr className="mt-5 my-4 text-white" />
            <h3 className="page-heading">Assigned Glyphs</h3>
            <p className="page-text">
              These are the Glyphs that your material is assigned to. This
              material will appear in the iOS app when someone scans your Glyph.
            </p>
            <Row>
              {materialGlyphs ? (
                materialGlyphs.map((glyph) => GlyphCard(glyph))
              ) : (
                <></>
              )}
            </Row>
            <Row className="justify-content-center mt-5 pt-5">
              <Col className="text-center">
                <p className="page-text">
                  Admins and Editors can assign Glyphs to this material in the
                  materials page
                </p>
                <Button
                  variant="outline-light"
                  className="btn-transparent-invert"
                  href="/materials"
                >
                  Go to Materials Page
                </Button>
              </Col>
            </Row>

            <Row className="mt-5 py-5">
              <Col className="d-flex justify-content-end">
                <Button
                  variant="outline-danger"
                  style={{ float: "right" }}
                  className="btn-delete-invert"
                  onClick={() =>
                    material &&
                    handleDeleteMaterial(role.organizationID, material.id)
                  }
                >
                  Delete Material
                </Button>
              </Col>
            </Row>

            <Modal show={showEditModal} onHide={handleEditModalClose}>
              <Modal.Header>
                <Modal.Title>Edit Material</Modal.Title>
                <button
                  type="button"
                  className="close modal-close-btn"
                  aria-label="Close"
                  onClick={handleEditModalClose}
                  style={{ color: "white" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <Form.Group as={Row}>
                    <Col md={6}>
                      <Form.Label>Name*</Form.Label>
                      <Form.Control
                        type="text"
                        value={editMaterialFormData.name}
                        required
                        placeholder="Enter a name"
                        maxLength={maxCharacterLimit20}
                        onChange={(e) => handleChange(e, "name")}
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Label>Visibility</Form.Label>
                      <Form.Select
                        value={editMaterialFormData.visibility}
                        required
                        onChange={(e) => handleChange(e, "visibility")}
                      >
                        <option value={PUBLIC_TAG}>Public</option>
                        <option value={PRIVATE_TAG}>Private</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="align-items-end">
                    <Col md={6}>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter a description"
                        maxLength={maxCharacterLimit500}
                        value={editMaterialFormData.description}
                        onChange={(e) => handleChange(e, "description")}
                      />
                    </Col>
                    <Col md={3}>
                      <br />
                      <Form.Label>Real World Scale (Width)*</Form.Label>
                      <Form.Control
                        type="number"
                        value={editMaterialFormData.scale.width.toString()}
                        placeholder="pixels per mm"
                        required
                        onChange={(e) => handleChange(e, "scale.width")}
                      />
                    </Col>
                    <Col md={3}>
                      <br />
                      <Form.Label>Real World Scale (Height)*</Form.Label>
                      <Form.Control
                        type="number"
                        value={editMaterialFormData.scale.height.toString()}
                        placeholder="pixels per mm"
                        required
                        onChange={(e) => handleChange(e, "scale.height")}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Col md={6}>
                      <Form.Label>Collections</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Specify collections for your material"
                        maxLength={maxCharacterLimit20}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                          handleBadgeAddition(e, "collection")
                        }
                      />
                      {Array.isArray(editMaterialFormData.collection) &&
                        editMaterialFormData.collection.map((item, index) => (
                          <Badge
                            as="span"
                            pill
                            className="badge-primary mr-2 mb-2"
                            key={index}
                            onClick={() =>
                              handleBadgeDeletion(item, "collection")
                            }
                          >
                            {item}
                            <span
                              onClick={() =>
                                handleBadgeDeletion(item, "collection")
                              }
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                            >
                              x
                            </span>
                          </Badge>
                        ))}
                    </Col>
                    <Col md={6}>
                      <Form.Label>Color</Form.Label>
                      <Select
                        placeholder="Choose the color(s) of your material"
                        onChange={(e) => {
                          handleBadgeAdditionSelectOnchange(e, "colors");
                        }}
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          handleBadgeAdditionSelect(e as any, "colors");
                        }}
                        options={colors_options}
                      />
                      {Array.isArray(editMaterialFormData.colors) &&
                        editMaterialFormData.colors.map((color, index) => (
                          <Badge
                            as="span"
                            pill
                            className="badge-primary mr-2 mb-2"
                            key={index}
                            onClick={() => handleBadgeDeletion(color, "colors")}
                          >
                            {color}
                            <span
                              onClick={() =>
                                handleBadgeDeletion(color, "colors")
                              }
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                            >
                              x
                            </span>
                          </Badge>
                        ))}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Col md={6}>
                      <Form.Label>Material Characteristics</Form.Label>
                      <Select
                        placeholder="Add characteristics to your material"
                        onChange={(e) => {
                          handleBadgeAdditionSelectOnchange(
                            e,
                            "characteristics"
                          );
                        }}
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          handleBadgeAdditionSelect(
                            e as any,
                            "characteristics"
                          );
                        }}
                        options={design_type_options}
                      />
                      {Array.isArray(editMaterialFormData.characteristics) &&
                        editMaterialFormData.characteristics.map(
                          (characteristic, index) => (
                            <Badge
                              as="span"
                              pill
                              className="badge-primary mr-2 mb-2"
                              key={index}
                              onClick={() =>
                                handleBadgeDeletion(
                                  characteristic,
                                  "characteristics"
                                )
                              }
                            >
                              {characteristic}
                              <span
                                onClick={() =>
                                  handleBadgeDeletion(
                                    characteristic,
                                    "characteristics"
                                  )
                                }
                                style={{ cursor: "pointer", marginLeft: "5px" }}
                              >
                                x
                              </span>
                            </Badge>
                          )
                        )}
                    </Col>
                    <Col md={6}>
                      <Form.Label>Utility</Form.Label>
                      <Select
                        placeholder="Specify the utility of your material"
                        onChange={(e) => {
                          handleBadgeAdditionSelectOnchange(e, "utility");
                        }}
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          handleBadgeAdditionSelect(e as any, "utility");
                        }}
                        options={utility_options}
                      />
                      {Array.isArray(editMaterialFormData.utility) &&
                        editMaterialFormData.utility.map((utility, index) => (
                          <Badge
                            as="span"
                            pill
                            className="badge-primary mr-2 mb-2"
                            key={index}
                            onClick={() =>
                              handleBadgeDeletion(utility, "utility")
                            }
                          >
                            {utility}
                            <span
                              onClick={() =>
                                handleBadgeDeletion(utility, "utility")
                              }
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                            >
                              x
                            </span>
                          </Badge>
                        ))}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Col className="pt-4">
                      <Form.Label>Material Design Type</Form.Label>
                      <Select
                        placeholder="Enter your material design type"
                        onChange={(e) => {
                          handleBadgeAdditionSelectOnchange(e, "designType");
                        }}
                        onKeyDown={(
                          e: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          handleBadgeAdditionSelect(e as any, "designType");
                        }}
                        options={design_type_options}
                      />
                      {Array.isArray(editMaterialFormData.designType) &&
                        editMaterialFormData.designType.map((design, index) => (
                          <Badge
                            as="span"
                            pill
                            className="badge-primary mr-2 mb-2"
                            key={index}
                            onClick={() =>
                              handleBadgeDeletion(design, "designType")
                            }
                          >
                            {design}
                            <span
                              onClick={() =>
                                handleBadgeDeletion(design, "designType")
                              }
                              style={{ cursor: "pointer", marginLeft: "5px" }}
                            >
                              x
                            </span>
                          </Badge>
                        ))}
                    </Col>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" className="btn-transparent-invert">
                    Update Material
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
            <Modal show={showConfirmImageModal} onHide={toggleImageModal}>
              <Modal.Header>
                <Modal.Title>Diffuse Image</Modal.Title>
                <button
                  type="button"
                  className="close modal-close-btn"
                  aria-label="Close"
                  onClick={() => toggleImageModal()}
                  style={{ color: "white" }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body className="dalle-image-modal-body">
                <div className="mb-5 dalle-image-preview-container">
                  {loadingImageGen ? (
                    <div className="text-center">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : generatedB64Image ? (
                    <div className="text-center">
                      <Image
                        src={generatedB64Image}
                        fluid
                        className="dalle-image-preview"
                      />
                    </div>
                  ) : (
                    <div className="text-center">Something went wrong!</div>
                  )}
                </div>
                <Form.Control
                  as="textarea"
                  value={diffuseImagePromp}
                  onChange={handleDiffuseImagePromptChange}
                  rows={3} // Specifies the number of rows to show, you can adjust this number
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-transparent-invert"
                  onClick={() => toggleImageModal()}
                >
                  Cancel
                </Button>
                <Button
                  disabled={loadingImageGen}
                  className="btn-transparent-invert"
                  onClick={generateDiffuseImage}
                >
                  Generate Another
                </Button>
                <Button
                  disabled={!generatedB64Image || loading}
                  className="btn-transparent-invert"
                  onClick={() =>
                    generatedB64Image &&
                    handleGeneratedDiffuseImageUpload(generatedB64Image)
                  }
                >
                  Use this Image
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Container>
    </>
  );
};

export default MaterialViewPage;

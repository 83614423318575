// @ts-ignore 
import { Glyph, GlyphThumbnailSize } from '@glyph-platforms/glyph-common';

export const getDefaultGlyphThumbnail = (glyph: Glyph): string | undefined => {
  // const fallbackThumbnail = glyph.patternImageFileURL || ''; // default to big thumbnail
  // If thumbnails, return the smallest one
  if (glyph.thumbnails) {
    const thumbnails = glyph.thumbnails;
    const config = thumbnails.find(
      // @ts-ignore
      (thumbnail) => thumbnail.size === GlyphThumbnailSize.sm
    );
    return config ? config.url : undefined;
  }

  // return fallbackThumbnail;
  return undefined;
};

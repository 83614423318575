import {
    collection,
    doc,
    setDoc,
    updateDoc,
    getDoc,
    getDocs,
    CollectionReference,
    orderBy,
    query,
    DocumentReference,
    writeBatch,
    arrayUnion,
    arrayRemove,
    deleteDoc,
    FieldPath,
  } from 'firebase/firestore';
  import { db } from '../firebase';
  import {
    DBCollections,
    GlyphID,
    IMaterial,
    IMaterialFileThumbnail,
    IMaterialFileThumbnailArray,
    MaterialFileType,
    MaterialID,
    MaterialVisibility,
    OrganizationID,
    UserID,
  } from '@glyph-platforms/glyph-common';
  import { getGlyph } from './glyphs';
  import { calculateNewScale } from '../../lib/materials';
  
  export interface CreateMaterialArgs {
    name: string;
    description: string;
    colors: string[];
    scale: { width: number; height: number };
    price: {
      value: number;
      currency: string;
      precision: number;
    };
    collection: string[];
    characteristics: string[];
    utility: string[];
    designType: string[];
    visibility: MaterialVisibility;
  }
  
  export interface UpdateMaterialArgs {
    name: string;
    description: string;
    colors: string[];
    scale: { width: number; height: number };
    price: {
      value: number;
      currency: string;
      precision: number;
    };
    collection: string[];
    characteristics: string[];
    utility: string[];
    designType: string[];
    visibility: MaterialVisibility;
  }
  
  const defaultMaterial: IMaterial = {
    organizationId: '',
    id: '',
    name: '',
    colors: [],
    scale: {
      width: 0,
      height: 0,
    },
    price: {
      value: 0,
      currency: 'USD',
      precision: 2,
    },
    collection: [],
    characteristics: [],
    utility: [],
    designType: [],
    description: '',
    active: false,
    files: {},
    createdBy: '',
    glyphIDs: [],
    visibility: 'private',
  };
  
  export const getMaterials = async (organizationId: string): Promise<IMaterial[]> => {
    const collectionRef = collection(
      db,
      `${DBCollections.Organizations}/${organizationId}/${DBCollections.Materials}`
    ) as CollectionReference<IMaterial>;
    const q = query(collectionRef, orderBy('timeCreatedUnix', 'desc'));
    const { docs } = await getDocs(q);
    return docs.map((doc) => doc.data());
  };
  
  export const getMaterial = async (
    organizationId: string,
    materialId: string
  ) => {
    const docRef = doc(
      db,
      `${DBCollections.Organizations}/${organizationId}/${DBCollections.Materials}/${materialId}`
    ) as DocumentReference<IMaterial>;
  
    const material = await getDoc(docRef);
    return material.data();
  };
  
  export const createMaterial = async (
    organizationID: OrganizationID,
    userID: UserID,
    createArgs: CreateMaterialArgs
  ) => {
    const ref = collection(
      db,
      `${DBCollections.Organizations}/${organizationID}/${DBCollections.Materials}`
    ) as CollectionReference<IMaterial>;
    const docRef = doc(ref);
    const id = docRef.id;
    const materialBody = mergeDefaultValues<IMaterial>(
      defaultMaterial,
      createArgs
    );
    const date = new Date();
    await setDoc(docRef, {
      ...materialBody,
      organizationId: organizationID,
      createdBy: userID,
      id,
      timeCreated: date.toISOString(),
      timeCreatedUnix: date.getTime(),
    });
  
    return {
      id,
      message: `Successfully created Material ${createArgs.name}`,
    };
  };
  
  export const updateMaterial = async (
    organizationId: string,
    materialId: string,
    updateArgs: UpdateMaterialArgs,
    originalDocument: IMaterial
  ) => {
    const hasScaleChanged =
      updateArgs.scale.width !== originalDocument.scale.width ||
      updateArgs.scale.height !== originalDocument.scale.height;
  
    const date = new Date();
  
    const updateRequest: Partial<IMaterial> = {
      ...updateArgs,
      timeUpdated: date.toISOString(),
      timeUpdatedUnix: date.getTime(),
    };
  
    // CAREFULY, THIS IS OVERWRITE ORIGINAL NESTED OBJS
    if (hasScaleChanged) {
      updateRequest.thumbnails = originalDocument.thumbnails;
      // Update file thumbnail scales
      for (const materialKey of Object.keys(
        originalDocument.files
      ) as MaterialFileType[]) {
        if (
          updateRequest.thumbnails &&
          updateRequest.thumbnails[materialKey]?.files &&
          originalDocument.files &&
          originalDocument.files[materialKey]?.size
        ) {
          const origSize = originalDocument.files[materialKey]?.size;
          if (!origSize) {
            continue;
          }
  
          for (const thumb of updateRequest.thumbnails[materialKey]?.files ||
            []) {
            const newScale = calculateNewScale(
              origSize,
              thumb.size,
              updateArgs.scale
            );
            thumb.scale = newScale;
  
            if (
              materialKey === MaterialFileType.Thumbnail &&
              originalDocument.defaultThumbnail &&
              thumb.maxSize === originalDocument.defaultThumbnail.maxSize
            ) {
              // update the thumbnail scale as well
              // const defaultThumbFieldPath = [defaultThumbnailKey, scaleKey].join(
              //   '.'
              // );
              // updateRequest = {
              //   ...updateRequest,
              //   [defaultThumbFieldPath]: newScale,
              // };
              updateRequest.defaultThumbnail = originalDocument.defaultThumbnail;
              updateRequest.defaultThumbnail.scale = newScale;
            }
          }
        }
      }
    }
  
    const ref = doc(
      db,
      `${DBCollections.Organizations}/${organizationId}/${DBCollections.Materials}/${materialId}`
    ) as DocumentReference<IMaterial>;
    await updateDoc(ref, updateRequest);
    return;
  };
  
  const mergeDefaultValues = <T>(defaultValues: T, setValues: Partial<T>) => {
    const mergedValues: T = { ...defaultValues };
  
    for (const key in setValues) {
      if (setValues.hasOwnProperty(key) && setValues[key] !== undefined) {
        mergedValues[key] = setValues[key] as T[Extract<keyof T, string>];
      }
    }
  
    return mergedValues;
  };
  
  export const assignMaterialsToGlyphs = async (
    userID: UserID,
    organizationId: OrganizationID,
    glyphIDs: GlyphID[],
    materialIDs: MaterialID[]
  ) => {
    // Check that the user has permission for each glyph
    const glyphs = await Promise.all(
      glyphIDs.map((id) => getGlyph(organizationId, id))
    );
    if (glyphs.some((glyph) => !glyph)) {
      throw new Error('We could not find your Glyph');
    }
    if (
      glyphs.some(
        (glyph) =>
          glyph!.organizationID !== organizationId
      )
    ) {
      throw new Error('User does not have permission to add this Glyph');
    }
  
    // Check that the user has permission for each material
    const materials = await Promise.all(
      materialIDs.map((id) => getMaterial(organizationId, id))
    );
  
    if (materials.some((material) => !material)) {
      throw new Error('We could not find your Material');
    }
    if (
      materials.some(
        (material) =>
          material!.organizationId !== organizationId
      )
    ) {
      throw new Error('User does not have permission to add this Material');
    }
  
    // Assign materials to glyphs
    // Do it in batches
    // get the firestore batch
    let batch = writeBatch(db);
    let batchSize = 0;
  
    for (const material of materials) {
      const materialRef = doc(
        db,
        `${DBCollections.Organizations}/${organizationId}/${
          DBCollections.Materials
        }/${material!.id}`
      ) as DocumentReference<IMaterial>;
  
      batch.update(materialRef, {
        glyphIDs: arrayUnion(...glyphIDs),
      });
  
      batchSize++;
  
      if (batchSize === 500) {
        await batch.commit();
        batch = writeBatch(db); // Initialize a new batch
        batchSize = 0;
      }
    }
  
    if (batchSize > 0) {
      await batch.commit();
    }
  
    return;
  };
  
  export const removeMaterialFromGlyph = async (
    userID: UserID,
    organizationId: OrganizationID,
    glyphID: GlyphID,
    materialID: MaterialID
  ) => {
    const docRef = doc(
      db,
      `${DBCollections.Organizations}/${organizationId}/${DBCollections.Materials}/${materialID}`
    ) as DocumentReference<IMaterial>;
  
    await updateDoc(docRef, {
      glyphIDs: arrayRemove(glyphID),
    });
  
    return;
  };
  
  export const deleteMaterial = async (
    organizationID: OrganizationID,
    materialID: MaterialID
  ): Promise<void> => {
    const material = await getMaterial(organizationID, materialID);
  
    if (!material) {
      throw new Error(`Material ${materialID} does not exist`);
    }
  
    if (material.organizationId !== organizationID) {
      throw new Error(`You do not have permission to delete this glyph`);
    }
  
    // Delete the material
    const docRef = doc(
      db,
      `${DBCollections.Organizations}/${organizationID}/${DBCollections.Materials}/${materialID}`
    ) as DocumentReference<IMaterial>;
  
    await deleteDoc(docRef);
  
    return;
  };
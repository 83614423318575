import React, { FunctionComponent } from "react";

interface LoadingScreenProps {
  displayText: string;
}

const LoadingScreen: FunctionComponent<LoadingScreenProps> = ({
  displayText,
}): JSX.Element => {
  return (
    <div className="loading-spinner d-flex flex-column justify-content-center align-items-center h-100 w-100">
      <div className="custom-spinner">
        <img src="/images/logo.svg" alt="" />
      </div>
      <p style={{ color: "white" }} className="text-center mt-3">
        {displayText}
      </p>
    </div>
  );
};

export default LoadingScreen;

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import MaterialsPage from './pages/MaterialsPage';
import GlyphsPage from './pages/GlyphsPage';
import GlyphViewPage from './pages/GlyphViewPage';
import MaterialViewPage from './pages/MaterialViewPage';
import TopNavbar from './components/TopNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AuthProvider from './hooks/useAuth/AuthProvider';
import RequireAuth from './components/AuthGuard/AuthGuard';


function App() {
  return (
    <Router>
      <AuthProvider>
        <TopNavbar />
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/materials" element={
              <RequireAuth redirectTo='/login'>
                <MaterialsPage />
              </RequireAuth>
            } />
            <Route path="/material-view/:materialId" element={
              <RequireAuth redirectTo='/login'>
                <MaterialViewPage />
              </RequireAuth>
            } />
            <Route path="/glyphs" element={
              <RequireAuth redirectTo='/login'>
                <GlyphsPage />
              </RequireAuth>
            } />
            <Route path="/glyph-view/:glyphId" element={
              <RequireAuth redirectTo='/login'>
                <GlyphViewPage />
              </RequireAuth>
            } />
            <Route path="/" element={<Navigate to="/glyphs" />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;


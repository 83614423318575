import {
    collection,
    getDocs,
    CollectionReference,
    orderBy,
    query,
    doc,
    getDoc,
    DocumentReference,
    where,
    deleteDoc,
    updateDoc,
  } from 'firebase/firestore';
  import { db } from '../firebase';
  import {
    DBCollections,
    Glyph,
    GlyphID,
    IMaterial,
    OrganizationID,
    UserID,
  } from '@glyph-platforms/glyph-common';
  
  export const getGlyphs = async (
    organizationId: OrganizationID
  ): Promise<Glyph[]> => {
    const collectionRef = collection(
      db,
      `${DBCollections.Organizations}/${organizationId}/${DBCollections.Glyphs}`
    ) as CollectionReference<Glyph>;
    const q = query(collectionRef, orderBy('timeCreatedUnix', 'desc'));
    const { docs } = await getDocs(q);
    return docs.map((doc) => doc.data());
  };
  
  export const getGlyph = async (
    organizationId: OrganizationID,
    glyphId: GlyphID
  ): Promise<Glyph | null> => {
    const docRef = doc(
      db,
      `${DBCollections.Organizations}/${organizationId}/${DBCollections.Glyphs}/${glyphId}`
    ) as DocumentReference<Glyph>;
    const docSnap = await getDoc(docRef);
  
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  };
  
  export const getGlyphMaterials = async (
    organizationId: OrganizationID,
    glyphId: GlyphID
  ): Promise<IMaterial[]> => {
    // Need to query all materials where glyphIDs contains glyphId
    const collectionRef = collection(
      db,
      `${DBCollections.Organizations}/${organizationId}/${DBCollections.Materials}`
    ) as CollectionReference<IMaterial>;
    const q = query(
      collectionRef,
      where('glyphIDs', 'array-contains', glyphId),
      orderBy('timeCreatedUnix', 'desc')
    );
    const { docs } = await getDocs(q);
    return docs.map((doc) => doc.data());
  };
  
  // This just deletes the glyph document, every thing else is cleaned up in a backend event listener
  // i.e. removing the glyph from all materials, deleting the glyph storage route, deleting the hash subcollection etc
  export const deleteGlyph = async (
    organizationId: OrganizationID,
    glyphId: GlyphID
  ): Promise<void> => {
    const glyph = await getGlyph(organizationId, glyphId);
  
    if (!glyph) {
      throw new Error(`Glyph ${glyphId} does not exist`);
    }
  
    if (glyph.organizationID !== organizationId) {
      throw new Error(`You do not have permission to delete this glyph`);
    }
  
    // Delete the glyph
    const docRef = doc(
      db,
      `${DBCollections.Organizations}/${organizationId}/${DBCollections.Glyphs}/${glyphId}`
    ) as DocumentReference<Glyph>;
  
    await deleteDoc(docRef);
  
    return;
  };
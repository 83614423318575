import {
    signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
    sendPasswordResetEmail as sendPasswordResetEmailFirebase,
    setPersistence as setPersistenceFirebase,
    Persistence,
    browserSessionPersistence,
    browserLocalPersistence,
  } from 'firebase/auth';
  import { auth } from '../../api/firebase/index';
  import React, {
    createContext,
    PropsWithChildren,
    useEffect,
    useState,
  } from 'react';
  import { RoleFE, UserFrontend } from '../../lib/types';
  import { isValidEmail } from '@glyph-platforms/glyph-common';
  import { convertUserFirebaseToUserFrontend } from '../../lib/converters';
  import { getUserRole } from '../../api/db/users';
  
  export interface AuthContextType {
    user: UserFrontend | null | undefined;
    role: RoleFE | null;
    signInWithEmailAndPassword: (
      email: string,
      password: string
    ) => Promise<UserFrontend>;
    sendPasswordResetEmail: (email: string) => Promise<void>;
    logout: () => Promise<void>;
    setPersistence: (rememberMe: boolean) => Promise<void>;
  }
  
  export const AuthContext = createContext<AuthContextType | null>(null);
  
  interface AuthProviderProps {}
  
  const AuthProvider = ({ children }: PropsWithChildren<AuthProviderProps>) => {
    /**
     * user = undefined -> unset (loading)
     * user = null -> unauthenticated
     * user = USER -> authenticated
     */
    const [user, setUser] = useState<UserFrontend | null | undefined | null>(
      undefined
    );

    /**
     * role = undefined -> unset (loading)
     */
    const [role, setRole] = useState<RoleFE | null>(null);
  
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
          const userData = convertUserFirebaseToUserFrontend(user);
  
          const role = await getUserRole(user.uid);
          setRole(role);
          setUser(userData);
        } else {
          setUser(null);
          setRole(role);
        }
      });
  
      return () => {
        unsubscribe();
      };
    }, []);
  
    const setPersistence = async (rememberMe: boolean) => {
      const persistence: Persistence = rememberMe
        ? browserLocalPersistence
        : browserSessionPersistence;
  
      await setPersistenceFirebase(auth, persistence);
    };
  
    const _refreshUser = async (): Promise<UserFrontend | null> => {
      if (auth.currentUser) {
        await auth.currentUser.reload();
      }
      const newUser = auth.currentUser
        ? convertUserFirebaseToUserFrontend(auth.currentUser)
        : null;
      setUser(newUser);
  
      return newUser;
    };
  
    const signInWithEmailAndPassword = async (
      email: string,
      password: string
    ): Promise<UserFrontend> => {
      if (!email) {
        throw new Error('Email is required');
      }
      if (!password) {
        throw new Error('Password is required');
      }
      if (!isValidEmail(email)) {
        throw new Error('Invalid email');
      }
  
      const { user } = await signInWithEmailAndPasswordFirebase(
        auth,
        email,
        password
      );
  
      await _refreshUser();
  
      return convertUserFirebaseToUserFrontend(user);
    };
  
    const logout = async (): Promise<void> => {
      await auth.signOut();
  
      await _refreshUser();
    };
  
    const sendPasswordResetEmail = async (email: string): Promise<void> => {
      if (!email) {
        throw new Error('Email is required');
      }
      if (!isValidEmail(email)) {
        throw new Error('Invalid email');
      }
  
      await sendPasswordResetEmailFirebase(auth, email);
    };
  
    return (
      <AuthContext.Provider
        value={{
          user,
          role,
          signInWithEmailAndPassword,
          sendPasswordResetEmail,
          logout,
          setPersistence,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  };
  
  export default AuthProvider;
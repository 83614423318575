import {
    FieldPath,
    Query,
    collectionGroup,
    getDocs,
    query,
    where,
    documentId,
    collection,
    CollectionReference,
    getDoc,
    doc,
    DocumentReference,
  } from 'firebase/firestore';
  import { RoleFE, UserDB } from '../../lib/types';
  import { db } from '../firebase';
  import {
    DBCollections,
    OrganizationID,
    Role,
    User,
    UserID,
  } from '@glyph-platforms/glyph-common';
  
  export const getUserRole = async (userID: UserID): Promise<RoleFE | null> => {
    const ref = collectionGroup(db, DBCollections.Roles) as Query<Role>;
    const q = query(ref, where('id', '==', userID));
  
    const { docs } = await getDocs(q);
    const role = docs[0]?.data();
  
    if (!role) {
      return null;
    }
  
    const roleFE: RoleFE = {
      roleType: role.role,
      organizationID: role.organizationID,
    };
  
    return roleFE;
  };
  
  export const getUserDB = async (
    organizationID: OrganizationID,
    userID: UserID
  ): Promise<UserDB | null> => {
    const ref = doc(
      db,
      `${DBCollections.Organizations}/${organizationID}/${DBCollections.Users}/${userID}`
    ) as DocumentReference<User>;
  
    const document = await getDoc(ref);
  
    if (!document.exists()) {
      return null;
    }
  
    const user = document.data();
  
    const userFE: UserDB = {
      id: user.id,
      email: user.email,
      username: user.username,
      timeCreatedUnix: user.timeCreatedUnix,
      avatar: user.avatarImageURL,
      isGod: !!user.isGod,
    };
  
    return userFE;
  };
/**
 * Extracts filename and extension from a remote file URL.
 *
 * @param {string} remoteUrl - The remote file URL.
 * @returns { {filename: string, extension: string} } - An object with the filename and extension.
 */
export const extractFilenameAndExtension = (remoteUrl: string): string => {
    const decodedUrl = decodeURIComponent(remoteUrl);
    const urlObj = new URL(decodedUrl);
    const pathname = urlObj.pathname;
    const parts = pathname.split('/');
    const fullFilename = parts[parts.length - 1];
    return fullFilename;
  };
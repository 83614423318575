import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../api/firebase';
import {
  DBCollections,
  Glyph,
  GlyphID,
  OrganizationID,
} from '@glyph-platforms/glyph-common';

export interface UseGlyphListenerProps {
  glyphID: GlyphID;
  organizationId: OrganizationID;
}

const useGlyphListener = (props: UseGlyphListenerProps) => {
  const [glyph, setGlyph] = useState<Glyph | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const docRef = doc(
      db,
      `${DBCollections.Organizations}/${props.organizationId}/${DBCollections.Glyphs}/${props.glyphID}`
    );
    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const glyph = snapshot.data() as Glyph;
          setGlyph(glyph);
        } else {
          setGlyph(null);
        }
        setLoading(false);
      },
      (error) => {
        alert(error.message);
        setLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [props.organizationId, props.glyphID]);

  return { glyph, loading };
};

export default useGlyphListener;
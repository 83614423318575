import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// @ts-ignore 
import reportWebVitals from './reportWebVitals';

const originalError = console.error;
console.error = (...args: any[]) => {  // Using any[] for args to accommodate different types of arguments
  if (/ResizeObserver loop limit exceeded/.test(args[0])) {
    return;
  }
  originalError.call(console, ...args);
};

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

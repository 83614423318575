import axios from 'axios';
import { auth } from '../firebase';
import { BACKEND_URL } from '../urls';

interface Options<T> {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  body?: T;
}

type EndpointURL = string;

export const glyphAPI = async <Res, Body>(
  endpointURL: EndpointURL,
  options?: Options<Body>
): Promise<Res> => {
  try {
    const { method, body } = { method: 'GET', body: null, ...options };

    const user = auth.currentUser;
    const token = user && (await user.getIdToken(true));

    const res = await axios(`${BACKEND_URL}/${endpointURL}`, {
      method,
      ...(body && { data: body }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data as Res;
  } catch (err) {
    throw err;
  }
};
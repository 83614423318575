import { MaterialID } from '@glyph-platforms/glyph-common';
import axios from 'axios';

interface CreateRecordParams {
    materialID: MaterialID;
    imageURL: string;
    prompt: string;
}
export const createRecord = async ({materialID, imageURL, prompt}: CreateRecordParams) => {
    const API_URL = 'https://api.airtable.com/v0/appBzbj1gLiGBtrml/Gemini%20Dalle%20Images';
    const API_TOKEN = process.env.REACT_APP_AIR_TABLE_API_KEY;

    if (!API_TOKEN) throw new Error('API_TOKEN is not defined');

    const data = {
        records: [
            {
                fields: {
                    "fldyXgDjaiRbOrOh8": materialID,
                    "fldEXjhRGniKtjKBE": [{url: imageURL}],
                    "fldZLqX9EYuvY8Q1P": prompt
                }
            }
        ]
    };

    const result = await axios.post(API_URL, data, {
        headers: {
            'Authorization': `Bearer ${API_TOKEN}`,
            'Content-Type': 'application/json'
        }
    })

    return result;
}
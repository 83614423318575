import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../api/firebase';
import {
  DBCollections,
  IMaterial,
  MaterialID,
  OrganizationID,
} from '@glyph-platforms/glyph-common';

export interface UseMaterialListenerProps {
  materialID: MaterialID;
  organizationId: OrganizationID;
}

const useMaterialListener = (props: UseMaterialListenerProps) => {
  const [material, setMaterial] = useState<IMaterial | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const docRef = doc(
      db,
      `${DBCollections.Organizations}/${props.organizationId}/${DBCollections.Materials}/${props.materialID}`
    );
    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const mat = snapshot.data() as IMaterial;
          setMaterial(mat);
        } else {
          setMaterial(null);
        }
        setLoading(false);
      },
      (error) => {
        alert(error.message);
        setLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [props.organizationId, props.materialID]);

  return { material, loading };
};

export default useMaterialListener;
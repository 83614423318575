import { User } from 'firebase/auth';
// @ts-ignore 
import { UserID } from '@glyph-platforms/glyph-common';
import { UserFrontend } from '../types';

export const convertUserFirebaseToUserFrontend = (user: User): UserFrontend => {
  const { uid, email, displayName, emailVerified, photoURL } = user;

  const userData: UserFrontend = {
    id: uid as UserID,
    email: email,
    isEmailVerified: emailVerified,
    username: displayName,
    avatar: photoURL,
  };
  return userData;
};

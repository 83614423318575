import axios from "axios";
import { OPEN_AI_IMAGE_GENERATION } from "../urls";

interface GenerateImagesRequest {
    promptText: string;
}

export const generateImages = async (request: GenerateImagesRequest): Promise<string> => {
    const response = await axios.post(
        OPEN_AI_IMAGE_GENERATION,
        {
            model: "image-alpha-001",
            prompt: request.promptText,
            n: 1,
            size: "1024x1024",
            response_format: "b64_json",
        },
        {
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_DALLE_API_KEY}`,
        },
        }
    );

    const b64Image: string[] = [];

    for (const image of response.data.data) {
        b64Image.push("data:image/png;base64," + image.b64_json);
    }

    return b64Image[0];
}